@import "./../../../Styles/theme";

.check-button {
  position: relative;
  width: 100%;
  height: 54px;
  margin-bottom: 15px;
  input {
    opacity: 0;
  }
  label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: -6px 0 13px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    border: 2px solid #e7e7e7;
    background-color: #f3f3f3;
    font-size: 1.5rem;
    padding: 12px;
    .icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 25px;
      font-size: 2.5rem;
    }
  }
  [type="checkbox"]:checked + label {
    background-color: $orange;
    color: $white;
    border-color: $orange;
  }
}
