@import "./../../Styles/theme";
@import "./../../Styles/placeholder";
.steps {
  margin-bottom: 20px;
  .step {
    width: 80px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;
    .arrowcls-basic {
      font-size: 50px;
      font-weight: 700;
      color: #d1d1d1;
  }
    
    .step-caption {
      color: $green;
    }
    &.step-fill {
      .step-caption {
        color: #000;
      }
      .arrowcls-basic {
          font-size: 50px;
          font-weight: 700;
          color: #00933a;
      }
      &::before {
        background-color: $green;
      }
    }
    &::before {
      content: "";
      width: 90px;
      height: 7px;
      position: absolute;
      left: 95%;
      top: 50%;
      transform: translateY(-50%);
      background-color: $step-color;
      z-index: -1;
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    .step-caption {
      font-size: 25px;
      color: $step-color;
      font-weight: 700;
    }

    &.active {
      background: url("./../../Assets/Icons/active-step.png");
      .step-caption {
        color: $green;
      }
      &::before {
        background-color: $green;
      }
    }
    // &.step-fill {
    //   background: url("./../../Assets/Icons/step-fill.png");
    //   .step-caption {
    //     color: $white;
    //   }
    //   &::before {
    //     background-color: $green;
    //   }
    // }
  }
}
.steps {
  justify-content: center;
}

.form-field.Form__writeOffZIndex {
  position: relative;
  z-index: 101;
}

.steps-form-wrapper {
  position: relative;
  .steps-form-details {
    .heading {
      text-align: center;
      @extend %heading;
      margin-bottom: 0.6rem;
      line-height: 2.9rem;
      padding: 0 0px;
    }
    .form-wrapper {
      margin: 0 auto;
      max-width: 610px;
      .col-100{
        width: 100%;
        float: left;
        .form-field{
          .field-label{
            margin-bottom: 0px;
          }
          .item{
            width: 100%;
            float: left;
          }
        } 
      }
      .custom-margin {
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
      .form-field {
        padding: 0 0px;
        .field-label {
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 10px;
          color: #2d2d2d;
        }
        .list-items {
          margin-top: 0px;
          textarea{
            padding: 10px;
            margin-bottom: 10px;
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: normal;
          }
          .item {
            font-size: 16px;
            font-weight: $tiny-bold;
            position: relative;
            color: #2d2d2d;
            margin-bottom: 10px;
            .circle-radio{
              margin-bottom: 0px;
              margin-top: 5px;
              label{
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 1.2rem;
                font-weight: 300;
                font-style: normal;
                letter-spacing: normal;
                line-height: 12px;
                &:before{
                  top: -4px;
                }
              }
            }
          }
        }
        .input {
          margin-bottom: 10px;
          color: #2d2d2d;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
        }
        .radio-list {
          .item {
            font-size: 16px;
            font-weight: $tiny-bold;
            position: relative;
            color: $secondary-black;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.stepper-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 100%;
  .next {
    display: block;
    width: 100%;
    margin: 15px 0px 0px;
    text-align: center;
  }
  .preview-finish {
    width: 100%;
    display: flex;
    .preview,
    .finish {
      width: 100%;
      display: block;
      margin: 15px 0px 0px;
    }
  }
}

.step-3 {
  display: flex;
  flex-wrap: wrap;
  .field-label {
    width: calc(100% - 184px);
  }
  .radio-button-wrapper {
    width: 174px;
    margin-left: auto;
  }
  .radio-button-wrapper {
    margin-bottom: 20px;
  }
}

.basic-details-wrapper{
  padding: 0;
  max-width: 420px;
  margin: 0 auto;
  margin-bottom: 0px;
  // height: calc(100vh - 375px);  
  padding-bottom: 0;  
}

.steps-form-details {
  padding: 0;
  margin: 0 auto;
  &.step-five {
    @extend %container1080;
  }
}

.steps-form-details.step-five{
  padding-left: 0;
  padding-right: 0;
}

.step-5 {
  .heading {
    margin-bottom: 1.5rem !important;
  }
  .subtitle {
    color: $secondary-black;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .msg-sent {
    border-radius: 4px;
    border: 2px solid #e5e5e5;
    background-color: #f9f9f9;
    padding: 30px;
    margin-bottom: 50px;
    .head {
      font-size: 1.5rem;
      font-weight: $tiny-bold;
      margin-bottom: 10px;
    }
    .title {
      color: #484848;
      font-size: 16px;
      font-weight: 400;
      span {
        font-weight: 700;
      }
    }
  }
  .smartphone {
    width: 100%;
    margin: 0 auto;
    .smartphone-image {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      display: block;
      margin-bottom: 50px;
    }
    .form-wrapper {
      margin-bottom: 50px;
    }
  }
}

.SingleDatePicker {
  margin-bottom: 20px;
}

.steps-form-wrapper{
  .steps-form-details{
    .form-wrapper{
      .form-field{
        .list-items{
          float: left;
          width: 100%;
          .item{
            .circle-radio{
              width: 50%;
              float: left;
              label{
                &:after{
                  width: 13px;
                  height: 13px;
                  top: -1.5px;
                  left: 3px;
                }
              }
            }
          }
        }
      }
    } 
  } 
} 

@media (max-width: 767px) {
  .steps {
    margin-bottom: 30px;
    .step {
      width: 65px;
      height: 57px;
      margin-right: 24px;
      &::before {
        width: 40px;
      }
      .step-caption {
        font-size: 25px;
      }
      .step-fill {
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .form-step {
    .form-steps-inner {
      max-width: 700px;
      margin-bottom: 30px;
    }
  }
  .steps-form-wrapper .steps-form-details {
    .heading {
      margin-bottom: 20px;
    }
    .form-wrapper {
      margin: 0 -10px;
      .form-field {
        padding: 0 10px;
      }
    }
  }
}

@media (max-width: 580px) {
  .steps {
    .step {
      width: 65px;
      height: 57px;
      margin-right: 24px;
      &::before {
        width: 40px;
      }
      .step-caption {
        font-size: 16px;
      }
      .step-fill {
      }
    }
  }
  .steps-form-wrapper {
    padding: 0 16px;
    .steps-form-details {

      .form-wrapper {
        margin: 0;
        .custom-margin {
          margin-bottom: 0;
        }
        > .d-flex {
          flex-wrap: wrap;
          .col-50 {
            width: 100% !important;
          }
        }
        .form-field {
          .list-items {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .stepper-button-wrapper {
    width: 100%;
  }
  .SingleDatePicker {
    margin-bottom: 40px;
  }
  .step-5 .msg-sent {
    margin-bottom: 40px;
  }
  .step-5 .smartphone {
    width: 100%;
    text-align: center;
    .smartphone-image {
      margin-bottom: 40px;
    }
  }
  .step-5 .smartphone .smartphone-image {
    width: 70%;
  }
}

// upload Images Section

.imageUpload__component {
  .imageUpload__upload_multiple_text {
    .form-width {
      width: 100%;
      margin: 0;
      svg {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
  .imageUpload__sectionHeader {
    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 0;
    }
    .Helper__component {
      margin-bottom: 48px;
      position: relative;
      a {
        border-color: #00933a;
        color: #00933a;
        transition: border-color 0.1s linear;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        line-height: 23px;
        border-bottom: 1px solid rgba(29, 29, 27, 0.3);
      }
    }
    p {
      font-size: 14px;
    }
  }

  .imageUpload__image_section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    margin-bottom: 40px;
    li {
      margin-bottom: 16px;
      padding-left: 5px;
      padding-right: 5px;
      .imageUpload__image_input_label {
        &:first-child {
          font-weight: 700;
        }
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 8px;
        display: block;
      }
      &.multi-images {
        width: auto;
        .photoInput-module_component__18191 {
          display: flex;
          background-color: #f2f2f2;
          flex-wrap: wrap;
          // background-color: transparent;          
          height: auto;
          min-height: 75px;
          min-width: 100px;
          label {
            padding-bottom: inherit;
          }
          .image-multi-item {
            position: relative;
            width: 100px;
            height: 75px;
            border-radius: 4px;
            .image-cropper {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .photoInput-module_component__18191 {
    background-color: #f2f2f2;
    border-radius: 4px;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    &.PhotoInput-module_image {
      background-color: #1d1d1b;
    }
    &.photoInput-module_medium__34JbJ {
      width: 100px;
      height: 75px;
      .image_input_delete {
        display: none;
      }
      &:hover {
        .image_input_delete {
          display: block;
          cursor: pointer;
          background: #de2524;
          border: none;
          color: #fff;
          width: 25px;
          height: 25px;
          border-radius: 5px;
          position: absolute;
          bottom: 6px;
          left: 6px;
          z-index: 1;
          font-size: 18px;
          line-height: 1;
        }
      }
    }
    img {
      display: block;
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    &.uploads {
      &.photoInput-module_medium__34JbJ {
        width: 100%;
      }
      &[data-name] {
        background-position: 50%;
        background-repeat: no-repeat;
      }
      &[data-name="exterior_front_driver"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/exterior_front_driver.svg);
      }
      &[data-name="exterior_rear_driver"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/exterior_rear_driver.svg);
      }
      &[data-name="exterior_front_passenger"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/exterior_front_passenger.svg);
      }
      &[data-name="exterior_rear_passenger"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/exterior_rear_passenger.svg);
      }
      &[data-name="interior_front_seats"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/interior_front_seats.svg);
      }
      &[data-name="interior_rear_seats"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/interior_rear_seats.svg);
      }
      &[data-name="interior_dashboard"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/interior_dashboard.svg);
      }
      &[data-name="interior_boot"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/interior_boot.svg);
      }
      &[data-name="wheels_front_driver"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/wheel.svg);
      }
      &[data-name="wheels_rear_driver"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/wheel.svg);
      }
      &[data-name="wheels_front_passenger"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/wheel.svg);
      }
      &[data-name="wheels_rear_passenger"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/wheel.svg);
      }
      &[data-name="tyre_tread_front_driver"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/tyre_tread_front_driver.svg);
      }
      &[data-name="tyre_tread_rear_driver"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/tyre_tread_front_driver.svg);
      }
      &[data-name="tyre_tread_front_passenger"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/tyre_tread_front_passenger.svg);
      }
      &[data-name="tyre_tread_rear_passenger"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/tyre_tread_front_passenger.svg);
      }
      &[data-name="damage_scratches"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_scratches.svg);
      }
      &[data-name="damage_dents"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_dents.svg);
      }
      &[data-name="damage_paintwork"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_paintwork.svg);
      }
      &[data-name="damage_missing_trims"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_missing_trims.svg);
      }
      &[data-name="damage_warning_lights"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_warning_lights.svg);
      }
      &[data-name="damage_windscreen"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_windscreen.svg);
      }
      &[data-name="damage_alloy_scuffs"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_alloy_scuffs.svg);
      }
      &[data-name="damage_tyres"] {
        background-size: auto 40px;
        background-image: url(../../Assets//Icons/damage_tyres.svg);
      }
    }
    label {
      display: block;
      padding-bottom: 75%;
    }
    input {
      display: none;
    }
    .image_input_add {
      display: block;
      cursor: pointer;
      background: #00933a;
      border: none;
      color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      position: absolute;
      bottom: 6px;
      right: 4px;
      z-index: 1;
      font-size: 18px;
      line-height: 1;
    }
    // svg {
    //   bottom: 8px;
    //   pointer-events: none;
    //   position: absolute;
    //   right: 8px;
    //   background: #00933a;
    //   border-radius: 50%;
    // }
  }
}

@media (max-width: 767px) {

  .add-vehicle, .form-steps{
    background-image: none !important;
  }
  .stepper-button-wrapper{
    .preview-finish{
      display: block;
      .col-50 {
          width: 100%;
          float: left;
      }
      .width-50 {
          width: 100%;
          .finish{
            float: left;
          }
      }
    }
  } 
  .steps-form-wrapper{
    .steps-form-details{
      .form-wrapper{
        .form-field{
          .list-items{
            margin-top: 0px;
            .item{
              float: left;
              .circle-radio {
                width: 100%;
                font-size: 1.3rem;
                margin-bottom: 0px;
              }
            } 
          } 
          .field-label{
            font-size: 1.2rem;
          }
        } 
      } 
    } 
  } 

  .steps{
    .step{
      &.step-fill{
        .arrowcls-basic{
          font-size: 50px;
        }
      } 
      .arrowcls-basic{
        font-size: 50px;
      }
    } 
  } 

  .imageUpload__component {
    .imageUpload__image_section {
      margin-bottom: 0px;
    }
    .imageUpload__sectionHeader {
      h4 {
        margin-top: 10px;
      }
      .Helper__component {
        margin-bottom: 30px;
      }
    }
  }

  .detailpage-inner{
    padding-left: 15px;
    padding-right: 15px;
    .heading{
      font-size: 1.5rem;
      padding: 10px 10px;
    }
    .content-p{
        padding: 10px 10px;  
    }
  }

  .detailpage{
    .summary-item{
      .specList{
        padding: 0px 0px;
        span{
          font-size: 1.2rem;
          padding-right: 10px;
        }
      }
    } 
  } 

}


@media (max-width: 480px) {


  .imageUpload__component{
    .imageUpload__image_section{
      margin: 0 0px;
      li{
        width: 50%;
      }
    } 
    .photoInput-module_component__18191{
      &.photoInput-module_medium__34JbJ{
        width: 140px;
        height: 140px;
        margin: 0 auto;
      }
    }
  } 

  .steps-form-wrapper {
      padding: 0 15px;
  }

}