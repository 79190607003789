@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.pricing-wrapper {
    margin-bottom: 72px;
    margin-top: 81px;
    background-image: url(../../Assets/Images/cargo-fixed-design.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-attachment: fixed;
    .container-contact {
      // @xtend %container1080;
    }   
  }

  .container-pricing {
    max-width: 690px;
    margin: 0 auto;
    padding-bottom: 40px;
    .pricing-main{
        border-radius: 10px;
        overflow: hidden;
    }
    .pricing-title {
        text-align: center;
        padding: 50px 0px 38px;
        h3{
            color: #2d2d2d;            
            font-size: 32px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 19.95px;
            text-align: center;            
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        p{
            color: #2d2d2d;            
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 18px;
            text-align: center;            
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
    }

  
  .columns {
    float: left;
    width: 33.3%;
    padding: 0px;
    background: #fff;
  }
  
  .price {
    list-style-type: none;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  
  
  .price .pricehead{    
    color: #ffffff;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 18px;
    text-align: center;
    font-style: normal;
    letter-spacing: 0.48px;
    background: #2d2d2d;
    line-height: normal;
  }
  
  .price li {    
    padding: 10px 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 41px;
    text-align: center;    
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    min-height: 45px;
  }
  
  .price .grey {
    background-color: #cfd1cf;
  }
  
}

@media (max-width: 767px) {

  .pricing-wrapper{
    margin-bottom: 0px;
  }

  .container-pricing{
    .pricing-title{
      padding: 30px 15px 20px;
      p{
        font-size: 13px;
      }
      h3{
        font-size: 20px;
        font-weight: 600;
      } 
    }
    .price{
      .pricehead{
        font-size: 13px;
      }
      li{
        font-size: 14px;
      }
    } 
  } 

}