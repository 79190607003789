.c-camera-feed {
    position: relative;
    canvas {
        width: 100%;
        
    }
    .c-camera-feed__viewer{
        video{
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
            // z-index: 0;
        }
    }
    button.take-photo {
        // position: absolute;
        // bottom: 20px;
        // width: 60px;
        // height: 60px;
        // border-radius: 100%;
        // background: #fff;
        // border: 1px solid #000;
        // margin: 0 auto;
        // display: block;
        // left: 0;
        // right: 0;
        // font-size: 0;
    }
}

.cancel-ok {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0px 20px;
    button{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #fff;
        border: 1px solid #000;
        margin: 0 auto;
        display: block;
    }
    .cancelbtn {
        float: left;
        background: #de2524;
        color: #fff;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }
    .okbtn{
      float: right;
      background-color: #00933a;
      border: #00933a;
      color: #fff;
      

        svg {
            fill: #fff;
        }
    }
}




#vid_container,#vid_container2 {
    position: fixed;
    top: 0;
    left: 0;
  }
  
  #video,#video2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    outline: none;
  }
  
  #gui_controls,.cancel-ok {
    position: fixed;
    background-color: #111; /*rgba(255, 0, 0, 0.5);*/
    z-index: 2;
    bottom: 0;
  }
  
  #video_overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    z-index: 10;
    background-color: #111;
  }
  
  /*  Button style from: 
      https://googlesamples.github.io/web-fundamentals/fundamentals/media/mobile-web-video-playback.html */
  .c-camera-feed{
    button {
      outline: none;
      position: absolute;
      color: white;
      display: block;
      opacity: 1;
      background: transparent;
      border: solid 2px #fff;
      padding: 0;
      text-shadow: 0px 0px 4px black;
      background-position: center center;
      background-repeat: no-repeat;
      pointer-events: auto;
      z-index: 2;
    }
    
  }
  
  #takePhotoButton {
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    width: 80px;
    height: 80px;
    background-image: url('../../../Assets//Images/img/ic_photo_camera_white_48px.svg');
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #takePhotoButton:active {
    background-color: #fff;
  }
  
  #toggleFullScreenButton {
    display: none;
    width: 64px;
    height: 64px;
    background-image: url('../../../Assets/Images/img/ic_fullscreen_white_48px.svg');
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #toggleFullScreenButton[aria-pressed='true'] {
    background-image: url('../../../Assets/Images/img/ic_fullscreen_exit_white_48px.svg');
  }
  
  #switchCameraButton {
    display: none;
    width: 64px;
    height: 64px;
    background-image: url('../../../Assets//Images/img/ic_camera_rear_white_36px.svg');
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #switchCameraButton[aria-pressed='true'] {
    background-image: url('../../../Assets/Images/img/ic_camera_front_white_36px.svg');
  }
  
  @media screen and (orientation: portrait) {

    

    .react-html5-camera-photo-fullscreen>img, .react-html5-camera-photo-fullscreen>video{
      width: auto !important;
      height: auto !important;
    }

    #container-circles {
      bottom: 0 !important;
    }

    .cancel-ok {      
      button{
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background: #fff;
          border: 1px solid #000;
          margin: 0 auto;
          display: block;
      }
      .cancelbtn {
          float: left;
          background: #de2524;
          color: #fff;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 20px;
      }
      .okbtn{
        float: right;
        background-color: #00933a;
        border: #00933a;
        color: #fff;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
  
          svg {
              fill: #fff;
          }
      }
  }


    /* portrait-specific styles */
  
    /* video_container (video) doesn't respect height... 
         so we will fill it in completely in portrait mode
      */
    #vid_container {
      width: 100%;
      height: 80%;
    }
    .slidevideo{
      width: 100%;
      height: 80%;
    }
  
    #gui_controls,.cancel-ok {
      width: 100%;
      height: 20%;
      left: 0;
    }
  
    #switchCameraButton {
      left: calc(20% - 32px);
      top: calc(50% - 32px);
    }
  
    #toggleFullScreenButton {
      left: calc(80% - 32px);
      top: calc(50% - 32px);
    }
  }
  
  @media screen and (orientation: landscape) {

    

    .cancel-ok{
      .cancelbtn {
          float: left;
          background: #de2524;
          color: #fff;
          left: 50%;
          top: 20px;
          transform: translateX(-50%);
          font-size: 20px;
      }
      .okbtn {
          float: right;
          background-color: #00933a;
          border: #00933a;
          color: #fff;
          left: 33%;
          transform: translateX(-50%);
          bottom: 20px;
          -webkit-transform: rotate(-90deg);
    -moz-transform: rotate-(90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
      }
    } 

    #vid_container {
      width: 100%;
      height: 100%;
    }

    .slidevideo{
      width: 100%;
      height: 100%;
    }
  
    #vid_container.left {
      left: 20%;
    }
  
    /* we default to right */
    #gui_controls,.cancel-ok {
      width: 20%;
      height: 100%;
      right: 0;
    }
  
    /* for the lefties */
    #gui_controls.left {
      left: 0;
    }
  
    #switchCameraButton {
      left: calc(50% - 32px);
      top: calc(18% - 32px);
    }
  
    #toggleFullScreenButton {
      left: calc(50% - 32px);
      top: calc(82% - 32px);
    }
  }
  

  .slidevideo {    
    background: rgba(0,0,0,0.5);
    video::-webkit-media-controls-start-playback-button {
      display: none !important;
  }
  video::-webkit-media-controls {
    display: none !important;
  }
}

