@import "./../../Styles/theme";
@import "./../../Styles/placeholder";
.auth-container {
  @extend %container1080;
  .auth-form-wrapper {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 50px;
    background: #e6e3e3;
    padding: 30px;
    &.logincls{
      .heading{
        font-size: 32px;
        line-height: 1.5;
        margin: 0px 0 30px;
        text-align: center;
      }
      .mass-for-otp{
        margin-top: 30px;
        margin-bottom: 25px;
        font-size: 15px;
      }
    }
    .form-field {
      @extend %auth-field;
    }
    .btn {
      margin: 0;
    }
  }
}

.auth-container-two {
  @extend %container500;
  margin-top: 50px;
  .head {
    .heading {
      margin-bottom: 30px;
      @extend %heading;
    }
  }
  .auth-form-wrapper {
    margin-bottom: 70px;
   
    .form-field {
      @extend %auth-field;
      .pre-wrap-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .pre-wrap-col {
          width: 50%;
          padding: 0 15px;
          .input {
            width: 100%;
          }
        }
      }
    }
  }
}

.loginauth-main {
  background: #f1f1f1;
  float: left;
  width: 100%;
  margin-bottom: 52px;
    margin-top: 81px;
    background-image: url(../../Assets/Images/cargo-fixed-design.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 133px);
    background-attachment: fixed;
}

@media (max-width: 767px){
  .auth-container-two{
    .auth-form-wrapper{
      margin-bottom: 30px;
    }
  } 
  .auth-container{
    .auth-form-wrapper{
      margin-top: 70px;
    }
  } 
}