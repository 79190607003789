@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.about-us {
  margin-bottom: 39px;
  margin-top: 81px;
  background-image: url(../../Assets/Images/cargo-fixed-design.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 125px);
  background-attachment: fixed;
  .container-about {
    max-width: 690px;
    margin: 0 auto;
    width: 100%;
    padding: 27px 30px;
    background: #fff;
    text-align: center;
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 135px);
    
    .steps.list-style-none {
        max-width: 390px;
        margin: 0 auto;
    }
    .heading {
      @extend %heading;
      margin-bottom: 1.5rem;
      line-height: 1.9rem;
     
    font-size: 30px;
    color: #2d2d2d;
    font-family: Poppins;
    /* font-size: 32px; */
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 19.95px;
    text-align: center;
    margin-top: 0px;
    }
    .text {
      font-size: 16px;
      margin-bottom: 16px;
      color: #2d2d2d;
      font-weight: 300;
      font-style: normal;
      text-align: left;
      letter-spacing: normal;
      line-height: 18px;
    }
  }
  .ourteam {
    .heading {
      margin-top: 1.5rem;
      font-size: 2rem;
    }
    .text{
        color: #2d2d2d;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: 22px;
        text-align: center;
    }
  }
  .ourteamlist {
    display: flex;
    padding-bottom: 0;
    flex-wrap: wrap;
    padding-top: 0.8rem;
    ul {
      list-style: none;
      margin: 0;
      text-align: center;
      width: 100%;
      float: left;
      display: block;
      li {
        width: 100%;
        text-align: center;
        padding: 0px 15px;
        max-width: 160px;
        display: inline-block;
        .ourteam-wrapper {         
          border-radius: 4px;
          box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
          display: flex;
          flex-direction: column;
          padding: 7px 9px;
          background-color: #1b893d;
        }
        .team-img {          
          .company-team {
            width: 100%;
          }
        }
        .team-name {
          padding-top: 19px;
          .littlename {
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            color: #fff;
          }
        }
        .team-title {
          .largtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            padding-bottom: 19px;
            color: #fff;
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {

.about-us{
  margin-top: 100px;
  min-height: 100%;
  .container-about{
    padding: 25px 15px;
    height: auto;
  }
  .ourteamlist{
    padding-bottom: 0;
    ul{
      li {
        width: 100%;
        text-align: center;
        padding: 0px 15px;
        margin-bottom: 100px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    } 
  } 
} 



}