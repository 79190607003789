@import "../../Styles/theme";
@import "../../Styles/placeholder";

.header {
  padding: 0px 0px 0 0px;
  width: 100%;
  z-index: 999;
  transition: all ease-in-out 200ms;
  position: fixed;
  top: 0;
  background: #fff;
  &.bg-white {
    .navigation {
      .menu-item {
        svg.setting.home {
          fill: #fff;
        }
      }
    }
  }
  .menu-icon {
    display: none;
  }
  .logo {
    width: 14.8125rem;
    a{
      outline: none;
    }
  }
  .navigation {
    width: calc(100% - 14.8125rem);
    padding-left: 11.25rem;
    transition: all ease-in-out 300ms;
    margin-top: 27px;
    .close-menu {
      display: none;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .menu {
      .menu-item {
        &.more-item {
          position: relative;
          .dropdown-menu-wrapper {
            display: none;
            opacity: 0;
            left: 50%;
            min-width: 248px;
            position: absolute;
            transform: translateX(-50%);
            transition: max-height 0.3s linear, opacity 0.3s linear;
            top: 32px;
            // &::after {
            //   bottom: -24px;
            //   content: "";
            //   display: block;
            //   height: 24px;
            //   position: absolute;
            //   width: 100%;
            // }
            .dropdown-menu {
              box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
              border-radius: 4px;
              overflow: hidden;
              &::after {
                background-color: $white;
                box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
                content: "";
                display: block;
                height: 12px;
                left: 50%;
                position: absolute;
                top: -7px;
                transform: rotate(45deg);
                width: 12px;
                z-index: -1;
              }
              .dropdown-menu-item {
                background-color: $white;
                .dropdown-item {
                  padding: 16px 24px;
                  display: block;
                  font-size: 1rem;
                  color: $primary-black;
                  font-weight: $boldest;
                  &:hover {
                    background-color: #f2f2f2;
                    border-color: transparent;
                  }
                }
              }
            }
          }
          &:hover {
            .dropdown-menu-wrapper {
              display: block;
              transition: max-height 0.3s linear, opacity 0.3s linear;
              opacity: 1;
            }
          }
        }
        .item {
          padding: 1rem;
          font-size: 1.2rem;
          color: #0b0b0b;
          font-weight: 700;
          border-color: transparent;
          display: flex;
          -webkit-tap-highlight-color: transparent;
          transition: none;
          margin: 0px 1rem;
          padding: 0.5rem 0;
          letter-spacing: 0.5px;
          &:hover {
            border-bottom: 1px solid #00933a;
          }
        }
        &.logincls {
          margin-left: auto;
          &.loginbg {
            background: #00933a;
            padding: 10px 20px;
            text-align: center;
            border-radius: 5px;
            .setting {
              margin-left: 0;
            }
          }
        }
        &.email {
          margin-left: 25px;
          .item {
            color: $black;
          }
          .icon {
            width: 20px;
            height: 15px;
          }
        }
        .btn {
          // Todo mixin
          border-radius: 10px;
          .icon {
            margin-right: 10px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  &.bg-white {
    padding: 0 0;
    background-color: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.47);
    transition: all ease-in-out 200ms;
    .navigation {
      .menu {
        .menu-item {
          &.email {
            .item {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.left-minus-100 {
  left: -120%;
}

.left-0 {
  left: 0;
}

.setting {
  cursor: pointer;
  margin-left: 30px;
  width: 18px;
  height: 18px;
}
.setting-menu {
  width: 300px;
  right: -120%;
  position: fixed;
  top: 0;
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
  transition: all 200ms ease-in-out;
  height: 100%;
  z-index: 1;
  &.show {
    right: 0;
    text-align: left;
  }
  .toggle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
    padding: 5px 15px;
    .icon {
      cursor: pointer;
    }
    h5{
      font-size: 14px;
    }
  }
  .profile,
  .logout {
    padding: 15px 15px;
    cursor: pointer;
    color: $black;
    &:hover {
      background-color: #dedede;
    }
  }

  .menu-on-full{
    display: block;
  }

  .menu-on-small{
    display: none;
  }
}
.profile-name {
  font-weight: bold;
  font-size: 14px;
}

@media (max-width: 1070px){
  .setting-menu{
    .menu-on-full{
      display: none;
    }
  
    .menu-on-small{
      display: block;
    }
  }
}

// @media (max-width: 1920px) {
//   .header {
//     padding: 2rem 0;
//   }
// }

@media (max-width: 1070px) {
  .setting {
    margin-left: auto;
    margin-right: 20px;
    margin-top: 2px;
  }
  .header {
    padding: 0px 0px 0 0px;
    background-color: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.47);
    
    .ref {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu-icon {
        display: block;
      }
      .navigation {
        position: fixed;
        top: 0;
        background-color: $white;
        width: 100%;
        height: 100%;
        padding-left: 0;
        transition: all ease-in-out 250ms;
        .close-menu {
          display: block;
        }
        .menu {
          margin-top: 50px;
          flex-direction: column;
          .menu-item {
            padding: 30px 0;
            &.more-item {
              .dropdown-menu-wrapper {
                top: 64px;
              }
            }
            &.email {
              margin: auto;
              .item {
                color: $primary-black;
              }
            }
          }
        }
      }
    }
  }
}

// .header-top{
//   .header-top-inner{
//     .customer-login{
//       .customer-toggle{
//         .text-primary {
//             color: #fff;
//             margin-left: 0 !important;
//         }
//       }
//     } 
//   } 
// } 

@media (max-width: 767px) {

  // .header-top{
  //   .header-top-inner{
  //     .customer-login{
  //       .customer-toggle{
  //         .btn {
  //             padding: 0px;
  //             font-size: 13px;
  //             font-weight: 600;
  //         }
  //       } 
  //     } 
  //   } 
  // } 

  .header {
    .logo{
      width: 9rem;
      float: left;
      .width-100{
        height: auto;
      }
    }
    .header-top{
      margin-bottom: 10px;
      .header-top-inner{
        .customer-login{
          //border-top: 25px solid #2d2d2d;
          margin-right: 20px;
        }
      } 
    } 
    .ref {
      display: block;
      .navigation {
        margin-top: 0;
        .menu {
          width: 100%;
          text-align: center;
          .menu-item {
            padding: 0px 0;
            &.email{
              width: 100%;
            }
          }
        }
      }
    }
    .navigation {
      .menu {
        .menu-item {
          .item {
            display: block;
            margin: 5px 0px;
          }
        }
      }
    }
  }
}


// New header


.header-top {
  background: #2d2d2d;
  height: 6px;
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 0px;
  .header-top-inner {
      width: 100%;
      display: block;
      position: relative;
      .customer-login {
        position: relative;
        width: 187px;
        float: right;
        height: 35px;
        border-top: 35px solid black;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        .customer-toggle{          
          font-size: 14px;
          margin: 0 auto;
          width: 187px;
          display: block;
          text-transform: capitalize;
          list-style: none;
          text-align: center;
          padding: 8px 0px;
          position: relative;
          left: -20px;
          top: -35px;
          .logincls{
            .btn{
              &:hover{
                box-shadow: none;
              }
            }
          }
        }
    }
  }
}

.header{
  .mg-bt{
    margin-bottom: 0px;
  }
}

.header{
  &.bg-white{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    .header-top{
      margin-bottom: 10px;
    }
    .mg-bt{
      margin-bottom: 3px;
    }
  }
}


@media (max-width: 1070px) {

  .header{
    .ref{
      .navigation{
        z-index: 1;
      }
    } 
  } 

  // .header-top{
  //   margin-bottom: 20px;
  //   .header-top-inner{
  //     .customer-login{
  //       width: 164px;
  //       .customer-toggle{
  //         width: 174px;
  //       }
  //     }
  //   } 
  // } 

}