@import "./../../Styles/theme";
@import "./../../Styles/placeholder";


.edit-profile{
    .heading{
        margin-bottom: 20px;
    }
    .edit-profilefield{
        .edit-profile-form{
            margin: 0px -15px;
            .submit-mass{
                padding: 0 15px;
                .btn{
                    margin: 15px 0px 0px;
                }
            }
            .form-field{
                width: 50%;
                display: inline-block;
                padding: 0 15px;
                margin-bottom: 15px;
                .text-uppercase {
                    text-transform: none;
                }
                label{
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .edit-profile{
        margin-bottom: 30px;
        .edit-profilefield{
            .edit-profile-form{
                .form-field{
                    width: 100%;
                }
            } 
        } 
    } 

}