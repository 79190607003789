
.upload-images-mobile-wrapper{
    max-width: 645px;
    margin: 0 auto;
    margin-top: 90px;
    padding: 0px 20px;
    width: 100%;
    .imageUpload__component{
        .photoInput-module_component__18191{
            .image_input_add{
                line-height: 28px;
                text-align: center;
                font-size: 25px;
            }
        } 
    } 
}

