@import "./../../../Styles/theme";

.radio-button {
  position: relative;
  height: 60px;
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  label {
    background-color: $border-color;
    position: absolute;
    width: calc(100% - 4px);
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0b0b0b;
    font-size: 19px;
    font-weight: 400;
    cursor: pointer;
  }
  [type="radio"]:checked + label {
    font-weight: 700;
    border: 2px solid $green;
  }
}
