@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.car-listing {
  .listing-head {
    @extend %container1080;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background-color: #f0f0f0;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .btn {
      width: 200px;
      margin: 0;
    }
    .heading {
      width: calc(100% - 430px);
      margin-left: 15px;
      margin-right: 15px;
      text-align: center;
    }
    .input {
      width: 200px;
    }
  }
  .listing-wrapper {
    @extend %container1080;
    .card-outer-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -25px;
      .card-wrapper {
        width: calc(100% / 3);
        padding-left: 10px;
        padding-right: 10px;
        .list-card {
          margin-bottom: 30px;
          box-shadow: 0 6px 0 rgba(0, 0, 0, 0.14);
          border-radius: 6px;
          border: 1px solid #e0e0e0;
          overflow: hidden;
          .avatar {
            width: 100%;
            height: 200px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .card-body {
            padding: 15px 15px 30px 15px;
            ul.tab {
              margin-bottom: 22px;
              justify-content: space-between;
              li {
                padding: 3px 15px;
                display: flex;
                align-items: center;
                border-radius: 4px;
                &.title {
                  background-color: #fef200;
                  font-size: 13px;
                  text-transform: uppercase;
                  color: $primary-black;
                }
                &.location {
                  border: 1px solid #d8d8d8;
                  background-color: #e4e4e4;
                  font-size: 11px;
                  color: #696969;
                  &.icon {
                    width: 10px;
                    padding-right: 10px;
                  }
                }
              }
            }
          }
          .card-heading {
            font-size: 17px;
            margin-bottom: 18px;
            color: $primary-black;
            line-height: 12px;
          }
          ul.feature {
            display: flex;
            margin-bottom: 15px;
            li {
              font-size: 12px;
              color: #797979;
              font-weight: $tiny-bold;
              padding-right: 20px;
              position: relative;
              &::after {
                position: absolute;
                right: 7px;
                top: 7px;
                content: "";
                height: 4px;
                width: 4px;
                background-color: #a1a1a1;
                border-radius: 50%;
              }
              &:last-child::after {
                display: none;
              }
            }
          }
          .guid-price {
            color: $secondary-black;
            font-size: 14px;
            font-weight: $boldest;
            padding-left: 24px;
            position: relative;
            &:after {
              top: 5px;
              left: 0;
              content: "";
              width: 15px;
              height: 15px;
              position: absolute;
              border-radius: 50%;
              background-color: #00bc80;
            }
          }
        }
      }
    }
  }
}

// @media
@media (max-width: 767px) {
  .car-listing .listing-wrapper .card-outer-wrapper .card-wrapper {
    width: 100%;
    padding: 0;
  }
  .car-listing .listing-wrapper .card-outer-wrapper {
    margin: 0;
  }
  .car-listing .listing-head {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
  }
}

@media (max-width: 600px) {
  .car-listing .listing-head {
    display: block;
    text-align: center;
  }
  .car-listing .listing-head .heading {
    width: 100%;
    padding: 30px;
    margin: 0;
  }
}
