@import "../../../Styles/theme";
@import "../../../Styles/keyframes";
@import "./../../../Styles/placeholder";

.input {
  padding: 0px 15px;
  border-radius: $border-radius;  
  background-color: $input-background;
  outline: none;  
  font-size: 16px;  
  height: 33px;
  border: 2px solid #d2d2d2;
  color: #2d2d2d;
  &:focus {
    border: 1px solid $green !important;
  }
  &.input-error {
    border: 1px solid $danger !important;
  }
  &.shake-email {
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
}

.error {
  // border: solid 1.5px $danger;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  font-size: 10px;
}
.helptest {
  color: $green;
  text-decoration: underline;
}

.input[type=""]:disabled {
  background-color: #dedede;
}

.error-custom {
  font-size: 10px;
  color: $danger;
}
