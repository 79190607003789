@import "./../../../Styles/theme";
.common-banner {
  position: relative;
  width: 100%;
  float: left;
  .banner-inage {
    width: 100%;
  }
  .common-container {
    display: flex;
    // background-color: #d1d1d1;
    align-items: center;
    position: relative;
    margin-bottom: 0px;
    .common-container-inner {
      max-width: 690px;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 0px;
      margin-top: 8px;
      padding-top: 5px;
      padding-bottom: 2px;
      .icon {
        width: 140px;
        display: inline-block;
        vertical-align: middle;
        img {
          width: 100%;
          display: block;
        }
      }
      .gideprice {
          width: 125px;
          display: inline-block;
          vertical-align: middle;
          background: #2d2d2d;
          border-radius: 4px;
          padding: 5px;
          .gideprice-cls {
              text-align: center;
              font-size: 2rem;
              font-weight: 900;
              background: #fff;
              width: 100%;
          }
        .gideprice-logo {
            text-align: center;
            padding: 5px 0px 0px;
            color: #fff;
            font-size: 1.27rem;
            letter-spacing: 1.5px;
            span{
             
              img{
                max-width: 100%;
                margin-bottom: -4px;
              }
            } 
            p{

             
              /* width: 10px; */
              /* height: 14px; */
              color: #ffffff;
              /* font-family: Gilroy; */
              font-size: 17px;
              font-weight: 500;
              margin-top: -2px;
            }
        }
      }
      .car-detail {
        width: calc(100% - 280px);
        padding-left: 20px;
        padding-right: 20px;
        color: $white;
        margin: 0 auto;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        .car-model {
          font-size: 2rem;
          font-weight: 700;
          color: #000000;
          letter-spacing: 1px;
          background: #edb92d;
          border: 4px solid #000;
          border-radius: 4px;
          max-width: 200px;
          margin: 0 auto;
          line-height: 1;
          padding: 3px 0px;
        }
        .car-name {
          font-size: 1.6rem;
          font-weight: $boldest;
          margin-bottom: 0;
        }
        .cg-row{
          width: 100%;
          float: left;
          text-align: center;
          .car-name{
            display: inline-block;
            color: #2d2d2d;
            line-height: 1;
          }
          .carmodel {
              display: inline-block;
              margin: 6px 0px 0px 15px;
              font-size: 1.6rem;
              color: #2d2d2d;
              line-height: 1;
          }
        }
        .detail {
          .info {
            font-size: 1.2rem;
            font-weight: 400;
            color: #2d2d2d;
            margin-right: 20px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              right: -12px;
              top: 50%;
              transform: translateY(-50%);
              background-color: #2d2d2d;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.container-wrapper-main{
  //overflow: hidden;
  //background-image: url(../../../Assets/Images/cargo-fixed-design.png);
 // background-repeat: no-repeat;  
  position: relative;
  // height: 100vh;
  //background-size: cover;
  width: 100%;
  float: left;
  //background-attachment: fixed;
  //min-height: calc(100vh - 153px);
}

@media (max-width: 767px) {

  .common-banner{
    .common-container{
      .common-container-inner{
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        box-sizing: border-box;
        .icon{
          -webkit-flex-grow: 0;
          flex-grow: 0;
          -ms-flex-order: 0;
          -webkit-order: 0;
          order: 0;
        }
        .gideprice{
          -webkit-flex-grow: 1;
          flex-grow: 1;
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
          max-width: 140px;
        }
        .car-detail{
          -webkit-flex-grow: 2;
          flex-grow: 2;
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
          margin-top: 10px;
        }
      }
    } 
  } 

  .car-informaion{
    .container-body{
      .form-wrapper{
        .form-field{
          .input-control{
            .error-custom{
              left: 0;
              right: 0;
              margin: 0 auto;
              top: 40px;
              &:after{
                transform: rotate(90deg) !important;
                left: 0 !important;
                top: -69px !important;
                right: 0 !important;
                margin-top: 0px !important;
              }
            }
          } 
        } 
      } 
    } 
  } 

  // .car-listing,.customer-review,.selling-process,.about-us,.contact-us,.wrapper{
  //   .common-banner{
  //     .common-container{
  //       .common-container-inner{
  //         .car-detail{
  //           .car-name{
  //             padding: 21px 0px;
  //           }
  //         } 
  //       } 
  //     } 
  //   } 
  // }
  
  
  .common-banner {
   
    padding: 8px 0 0px 0;
   
    background-size: cover;
    // display: flex;
    align-items: center;
    margin-bottom: 0px;
    .common-container{
      .common-container-inner{
        .gideprice{
          margin: 10px auto 0;
          display: block;
        }
      } 
    } 
    .banner-image {
      display: none;
    }
    .common-container {
      position: static;
      margin-bottom: 0px;
      .common-container-inner {
        justify-content: center;
        margin-bottom: 0px;
        padding-top: 10px;

        .icon {
          width: 120px;
          margin: auto;
          display: block;
          img {
            width: 100%;
          }
        }
        .car-detail {
          padding-left: 15px;
          padding-right: 15px;
          width: 100%;
          .car-valuation-price{
            position: relative;
            margin: 10px 0px;
          }
          .car-model {
            font-size: 14px;
          }
          .car-name {
            font-size: 16px;
            margin-bottom: 4px;
          }
          .detail {
            .info {
              font-size: 12px;
              margin-right: 20px;
              &::after {
                width: 4px;
                height: 4px;
                right: -12px;
                top: 9px;
              }
            }
          }
        }
      }
    }
  }
}
