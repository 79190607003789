
@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.detailpage {
    margin-bottom: 52px;
    margin-top: 81px;    
    background-image: url(../../Assets/Images/cargo-fixed-design.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 153px);
    background-attachment: fixed;
    .container-review-details {
      max-width: 690px;
      margin: 0 auto;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      background: #fff;
    }
  }

.detailpage{
    .summary-item{
      border-top: 2px solid #e2e1d9;
      margin-top: 30px;
      padding-top: 30px;
      .imageUpload__component{
        .imageUpload__sectionHeader{
          h4{
            margin-bottom: 15px;
          }
        } 
      } 
      .specList{
        margin: 30px 0px 0px;
        padding: 0px 20px;
        list-style: none;
        li{       
           display: block;
           padding: 10px 0;
           border-top: 1px solid #f1f1f1;
           &:first-child{
             border-top: none;
           }
           span{
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 8px;
            width: 70%;
            display: inline-block;
            padding-right: 20px;
            &:nth-child(2){
              font-weight: 400;
              width: 30%;
              padding-right: 0;
              padding-left: 20px;
              text-transform: capitalize;
            }
           }
        }
      }
      .cg-link{        
        text-align: right;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: #fff;
        background-color: $green;;
        font-size: 1.125rem;
        text-transform: uppercase;
        line-height: 2rem;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 3px;
        cursor: pointer;
      }
      .heading{     
        position: relative;
        background: #f1f1f1;
        padding: 10px 20px; 
        svg,span {
            display: inline-block;
            vertical-align: middle;
        }
        svg{
          margin-right: 10px;
        }
      } 
    } 
  }

  .detailpage-inner{
    max-width: 420px;
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 0;
    padding: 40px 0px 0px;
    .heading{
        font-size: 2.2rem;
        line-height: 3.9rem; 
    }
    p{
      font-size: 16px;
    }
  }

