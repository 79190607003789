@import "./../../../Styles/theme";

.steps-form-wrapper .steps-form-details .form-wrapper .form-field .list-items{
  position: relative;
}

.writtenoff {
  position: absolute;
  width: calc(100% - 4px);
  top: 0px;
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  .Form__writeOffExplaination {
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 24px;
    text-align: center;
    max-width: 100%;
    border: 2px solid #dc2c2d;
    .returntohome{
      font-size: 16px;
      display: block;
    }
    p {
      font-size: 16px;
      padding-bottom: 16px;
    }
    &:before {
      // background-color: #f2f2f2;
      // content: "";
      // display: block;
      // height: 16px;
      // left: calc(50% - 8px);
      // position: absolute;
      // top: -8px;
      // transform: rotate(45deg);
      // width: 16px;
    }
  }
  .Form__writeOffZIndex {
    position: relative;
    z-index: 101;
  }
}

.Form__writeOffOverlay {
  background-color: hsla(0, 0%, 100%, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}


@media (max-width: 767px) {

  .writtenoff{
    position: relative;
  }

}