.form-steps {
  .form-steps-inner {
      overflow: hidden;
      position: relative;
      width: 100%;
     margin-bottom: 52px;
  }
}

.steps-form-wrapper{
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 246px);
    .steps.list-style-none {
        max-width: 390px;
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
  .form-steps {
    .form-steps-inner {
      max-width: 700px;
    }
  }
}

.car-detail-photo-info{
  .accordion-wrapper{
    .accordion-items{
      margin-bottom: 30px;
      border-bottom: 1px solid #d3d3d3;
      padding-bottom: 30px;
      &:last-child{
        border-bottom: none;
      }
      .accordion-content{
        p{
          font-size: 16px;
          margin-bottom: 15px;
          .item{
              font-size: 16px;
              color: #00933a;
              margin-left: 5px;
          }
        }
        .caroutput{
          border: solid 1px #cfcfcf;
          width: 100%;
          font-size: 16px;
          text-align: left;
          padding: 10px;
          border-radius: 3px;
          margin-bottom: 15px;
        }
        .cm-dt-btn{
          width: 100%;
          .item{
            border: solid 1px #cfcfcf;
            width: 100%;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            padding: 10px;
            border-radius: 3px;
            margin-bottom: 15px;
            display: block;
            color: #00933a;
          }
        }
      }
    }
    .accordion-title {
      &.open{
        padding-bottom: 20px;
        .head{
          &:after{
            content: url(../../Assets/Icons/minus.svg);
          }
        }
      }
      .head{
        position: relative;
        &:after{
          content: url(../../Assets/Icons/plus.svg);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          font-size: 30px;
          width: 30px;
          text-align: center;
          height: 30px;
          border: 1px solid #ccc;
          line-height: 30px;
          cursor: pointer;
          border-radius: 50%;
        }  
      }
    }
    .accordion-item{
      &.collapsed{
        display: none;
      }
    }
  }
}

.car-detail{
  position: relative;
  .car-valuation-price{
    position: absolute;
    top: 0;
    right: 0;
    .columns{
      .price{
          list-style: none;
          padding: 0;
          margin: 0;
          text-align: center;
          .price-title {
              text-transform: uppercase;
              background-color: #00933a;
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              padding: 5px 10px;
          }
          .grey {
              background-color: #eee;
              font-size: 16px;
              color: #000;
              padding: 5px 0;
          }
      }
    }
  }
}

@media (max-width: 767px) {

  .steps-form-wrapper{
    height: auto;
    overflow-y: initial;
  }

  .car-detail-photo-info{
    .accordion-wrapper{
      .accordion-items{
        margin-bottom: 20px;        
        padding-bottom: 20px;
        text-align: left;
      }
    } 
  } 

  .car-detail-photo-info{
    .accordion-wrapper{
      .accordion-items{
        .accordion-content{
          p{
            font-size: 1rem;
          } 
        } 
      } 
      .accordion-title{
        .head{
          font-size: 1rem;
          letter-spacing: 1px;
        }
      } 
    } 
  } 

}