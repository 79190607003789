@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.customer-profile { 
  .common-banner {
    margin-top: 81px;
    margin-bottom: 0;
  }
  background: linear-gradient( 
    180deg
     ,hsla(0,0%,95.7%,.96) 0,rgba(191,191,190,.91) 21%,hsla(0,0%,49.4%,.82) 41%,hsla(0,0%,39.2%,.94) 59%,hsla(0,0%,40.8%,.94) 50%,rgba(198,199,200,.94) 100%);
        margin-bottom: 0;
     //   height: calc(100vh - 128px);
        padding-bottom: 0;
  .container-customer {
    @extend %container1080;
    margin-top: 76px;
    width: 100%;
    padding: 0px 30px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 220px);    
  }
}

.profilwrapper {
  width: 100%;
  display: flex;
  margin: 0px 0 30px;
}

.customer-profile {
    .filterSection{
        width: auto;
        float: left;
        text-align: right;
        margin-bottom: 32px;
        .cg-select {
          border: 2px solid #61615f;
          border-radius: 4px;
          background-color: transparent;
          font-size: 16px;
          font-weight: 400;
          height: 48px;
          margin: 0;
          outline: none;
          text-align: left;
          width: 250px;
          padding: 0 32px 0 16px;
          cursor: pointer;
      }        
    }
    .sideBarSection{
        display: block;
        width: 28%;        
        padding-right: 20px;
        float: left;
        .sideBarSection-inner {
          background: #2d2d2d;
          padding: 0px;
          .sideBarSection-cg{           
            display: block;
            .navbar__link{
              color: #fff;
              font-size: 18px;
              font-weight: bold;
              line-height: 24px;
              padding: 20px 20px;
              display: block;
              border-bottom: 2px solid green;
              &.Active-custome{
                color: #fff;
                background: #00933a;
              }
            }
            .active{
              color: #fff;
              background: green;

            }
          }
          
          .sideBarSection-db{
            display: none;
            padding: 20px 0px;            
            width: 100%;
            ul{
              list-style: none;
              margin: 0;
              padding: 0;
              li{
                width: 100%;
                padding: 10px 0;
                a{
                  font-size: 16px;
                  color: #000;
                }
              }
            }
          }
      }
    }
    .vehiclesSection{
        width: 72%;
        float: right;
        ul{
          list-style: none;
          margin: 0;
          padding: 0;
          li{
            background: #f1f1f1;
            float: left;
            width: 100%;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(29, 29, 27, 0.5);
            contain: content;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            margin-bottom: 20px;
            .item{
              width: 100%;
              display: block;
              vertical-align: middle;
              -webkit-transform: perspective(1px) translateZ(0);
              transform: perspective(1px) translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              position: relative;
              -webkit-transition-property: color;
              transition-property: color;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              &:before{
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #cecece;
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
              }
              &:hover{
                &:before{
                  -webkit-transform: scaleY(1);
                  transform: scaleY(1);
                }
              }             
            }
          }
        }        
    }
}

@media (max-width: 1070px){
  .customer-profile{

    .sideBarSection{
      display: none;
    }
    .vehiclesSection{
      width: 100%;
    }
  }
}

.myvehicle{
  &.awaitin-submission{
    .accountVehicleCardView{
      .vehiclesSection-rgt{
        width: calc(100% - 240px);
        .viewdetail{
          top: 8px;
          transform: none;
        }
      }
    } 
    .vehiclesSection-lft{
      max-width: 200px;
      .photoArea{
        img{
          max-height: 150px;
        }
      }
    }
  }
  .accountVehicleCardView{
      .vehiclesSection-rgt{
          .vrmArea{
              margin-bottom: 7px;
          }
          .viewdetail{
              display: inline-block;
              vertical-align: top;
              position: absolute;
              right: 20px;
              top: 50%;
              text-align: center;
              transform: translateY(-50%); 
              .btn{
                  width: 100%;
                  display: block;
                  text-align: center;
                  margin: 6px auto;
                  padding: 2px 10px;
                  &.chatbtn{
                    position: relative;
                    height: 28px;
                    background-color: $orange;
                    svg{
                      position: absolute;
                      left: 0;
                      right: 0;
                      margin: 0 auto;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
              }
          }
      } 
  } 
}

.accountVehicleCardView{
  .vehiclesSection-lft{
    width: 100%;
    max-width: 270px;
    float: left;
    .photoArea{
       width: 100%;
       img{
        width: 100%;
        height: 100%;
         display: block;
         max-height: 203px;
       }
    }
  }
  .vehiclesSection-rgt {
      width: calc(100% - 310px);
      float: right;
      padding: 14px 0px 14px 10px;
      box-sizing: border-box;
      .feature{
        clear: left;
        li{
          display: inline-block !important;
          width: auto !important;
          box-shadow: none !important;
          font-size: 13px;
          color: #797979;
          font-weight: 400;
          padding-right: 20px;
          position: relative;
          margin-bottom: 0;
          background: transparent;
          &:after{
            position: absolute;
            right: 7px;
            top: 7px;
            content: "";
            height: 4px;
            width: 4px;
            background-color: #a1a1a1;
            border-radius: 50%;
          }
          &:last-child{
            &:after{
              display: none;
            }
          }          
        }
      }
      .vrmArea{
        float: left;
        .vrmBadge-styles_badge {
          font-size: 18px;
          text-transform: uppercase;
          color: #2d2d2d;
          border: 4px solid #000;
          background-color: #edb92d;
          border-radius: 6px;
          line-height: 100%;
          letter-spacing: 1.42px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          padding: 3px 6px;
          align-items: center;
          font-weight: 700;
      }
      }
      .expirationArea{
       float: right;
      }
      .bidareacls {
        width: calc(100% - 140px);
          float: left;
          text-align: left;
          clear: both;
      }
      .nameArea{
        width: 100%;
        float: left;
        text-align: left;
        clear: left;
        span {
            font-size: 16px;
            color: #000;
            margin: 0px 0px;
            display: block;
        }
      }
      .bids-status{
        display: block;
        vertical-align: middle;
        .mw-title-title6{
          font-weight: 600;
          font-size: 15px;
          color: #000;
          margin-right: 5px;
        }
        .running{
          font-size: 15px;
          color: #000;
          margin-right: 5px;
        }
      }
      .viewdetail{
        display: inline-block;
        vertical-align: middle;
        float: right;
            
      }
      .bidArea{
        display: block;
        vertical-align: middle;
        a.CommentIcon-cls {
            float: right;
        }
        span {
            font-size: 15px;
            color: #000;
            margin-right: 5px;
            &.mw-title-title6{
              font-weight: 600;
            }
            &.createdAt{
              margin-right: 0;
            }
        }
      }
  } 
  
}

.backtolist {
  text-align: right;
  margin-bottom: 20px;
  .btn{
    margin: 0;
    padding: 12px 20px;
    display: inline-block;
  }
}



 

.bidlistsub{
  .accountVehicleCardView{
    .vehiclesSection-rgt{
      .nameArea{       
        span{
          display: inline-block;
          margin-right: 10px;
          &.sellername{
            font-weight: 700;
          }
        }
      }
      .viewaction-amount {
          width: 100%;
          float: left;          
          .bidArea{
            width: auto;
            display: inline-block;
            span{
              &.bidamount{
                font-weight: 700;
              }
            }
            
          }
          .actionbtn{
            width: auto;
            display: inline-block;
            .btn{
              margin: 0px 5px;
              padding: 7px 25px;
              &.redcolor{
                background-color: #de2524;
              }
              &.yellowcolor{
                background-color: #f39000;
              }
              &:last-child{
                width: 45px;
                position: relative;
                display: inline-block;
                height: 29px;
                padding: 0;
                vertical-align: middle;
                svg{
                  position: absolute;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
            a{
              vertical-align: middle;
            }
          }
      }
    }
  }   
}


.pull-right{
  &.pagination{
    text-align: center;
    margin: 0 auto;
    display: block;
    li{
      display: inline-block !important;
      width: 40px !important;
      height: 40px !important;
      text-align: center;
      line-height: 40px;
      border-radius: 50% !important;
      margin: 0px 5px;
      float: none !important;
      transition-property: color;
      transition-duration: 0.3s;
      &:hover{
        background-color: $orange;
        a{
          color: #fff;
        }
      }      
      a{
        cursor: pointer; 
        display: block;
        outline: none;       
      }
      &.active{
        background-color: $green;
        a{
          color: #fff;
        }
      }
      &.previous{
        a{
          font-size: 0;                   
          &:before{
            content: "<<";
            font-size: 16px;
          }
        }
      }
      &.next{
        a{
          font-size: 0;
          &:before{
            content: ">>";
            font-size: 16px;
          }
        }
      }
    }
  }
} 

.bidlistsub{
  .accountVehicleCardView{
    .vehiclesSection-rgt{
      .vrmArea{
        width: 100%;
      }
      .nameArea{
        width: auto;
        float: none;
        display: inline-block;
        vertical-align: middle;
      }
    } 
  } 
}

@media (max-width: 1024px) and (min-width: 768px){
  

  .accountVehicleCardView{
    .vehiclesSection-lft{
      max-width: 200px;
    }
    .vehiclesSection-rgt{
      width: calc(100% - 200px);
      padding: 14px 10px 14px 10px;
    }
  } 

}

@media (max-width: 767px) {

  .customer-profile{
    .common-banner{
      margin-top: 96px;
      .common-container-inner{
        padding-top: 0;
        .car-detail{
          padding: 0px 0px !important;
          .car-name{
            font-size: 2rem;
          } 
        }
      } 
    }
  } 

  .myvehicle{
    .accountVehicleCardView{
      .vehiclesSection-rgt{
        .viewdetail{
          position: relative;
          transform: none;
          width: 100%;
          right: initial;
          top: initial;
        }
      } 
    } 
  } 
   
  .profilwrapper{
    display: block !important;
    float: left;
  }
  
  .customer-profile{
    .filterSection{
      .cg-select{
        width: 140px;
      }
    } 
    .container-customer{
      margin-top: 0 !important;
      height: auto;
      padding: 0px 15px;
      
    }
    .vehiclesSection{
      width: 100%;
    }
  } 

  .accountVehicleCardView{
    .vehiclesSection-lft{
      float: none;
      margin: 0 auto;
    }
    .vehiclesSection-rgt{
      padding: 10px;
      box-sizing: border-box;
      width: 100% !important;
      .viewaction-amount{
        .bidArea{
          width: 100%;
          float: left;
        }
        .actionbtn{
          width: 100% !important;
          float: left;
          .btn{
            margin: 10px 5px !important;
            display: inline-block;
            padding: 7px 15px !important;
            &:first-child{
              margin-left: 0 !important;
            }
            &:last-child{
              height: 38px !important;
            }
          }
        }
      } 
      .nameArea{
        width: 100%;
        float: left;
      }
      .bidareacls{
        width: 100%;
        float: left;
        .bidArea,.bids-status,.viewdetail{
          width: 100%;
          float: left;
          position: relative;
          top: 0;
          transform: none;
          right: 0;
          a{
            display: inline-block;
            margin-left: 0;
          }
        }
      }
    }
  }

}
.customer-profile {
  .common-banner{
      .common-container-inner {
          .car-detail{
              .car-name{
                  line-height: 45px !important;
              }
          }
      }
  }
}