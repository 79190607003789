@import "./../../Styles/theme";
@import "./../../Styles/placeholder";
.vehicle-detail {
    background: linear-gradient( 
        180deg
         ,hsla(0,0%,95.7%,.96) 0,rgba(191,191,190,.91) 21%,hsla(0,0%,49.4%,.82) 41%,hsla(0,0%,39.2%,.94) 59%,hsla(0,0%,40.8%,.94) 50%,rgba(198,199,200,.94) 100%);
    
  .vehicle-detail-body {
    @extend %container1080;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    .car-detail-column-left {
      width: 50%;
      .title {
        color: $primary-black;
        font-size: 24px;
        font-weight: 700;
        border-radius: 4px;
        background-color: $yellow;
        line-height: 16px;
        display: inline-block;
        padding: 7px 25px;
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      .heading {
        @extend %heading;
        margin-bottom: 16px;
        line-height: normal;
      }
      .car-info {
        margin-bottom: 16px;
        .info {
          font-size: 14px;
          font-weight: $tiny-bold;
          color: #2d2d2d;
          margin-right: 25px;
          position: relative;
          font-weight: $tiny-bold;
          &::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            right: -15px;
            top: 10px;
            background-color: #2d2d2d;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
      .car-feature {
        display: flex;
        flex-wrap: wrap;
        li {
          border-radius: 4px;
          border: 1px solid #d8d8d8;
          background-color: #e4e4e4;
          font-size: 12px;
          font-weight: $bold;
          padding: 10px 12px;
          margin-right: 5px;
          margin-bottom: 5px;
          color: #696969;
          display: inline-block;
          line-height: 13px;
        }
      }
      .additional-spec {
        margin-bottom: 25px;
        .common-heading {
          color: $primary-black;
          font-size: 18px;
          font-weight: $bold;
          .spec-text {
            font-size: 14px;
            color: $secondary-black;
            line-height: 25px;
            display: inline-block;
            font-weight: $tiny-bold;
          }
        }
      }
    }
    ul.feature {
      display: flex;
      margin-bottom: 15px;
      li {
        font-size: 12px;
        font-weight: bold;
        color: #0a0a0a;
        padding-right: 20px;
        position: relative;
        &::after {
          position: absolute;
          right: 7px;
          top: 7px;
          content: "";
          height: 4px;
          width: 4px;
          background-color: #a1a1a1;
          border-radius: 50%;
        }
        &:last-child::after {
          display: none;
        }
      }
    }
    .car-detail-column-right {
      width: 50%;
      padding-left: 20%;
      .progress-card {
        position: relative;
        background-color: #d2d2d2;
        padding: 30px 0px 30px;
        .valuations-cg{
          .valuations{
            display: none;
          }
          &.active{
            .valuations{
              display: block;
            }
            .valuations-title{
              &:after{
                background-image: url(../../Assets/Icons/chevron-up.svg);
              }
            }
          }
          .valuations-title{
            font-size: 16px;
            padding: 10px 16px;
            position: relative;
            border-bottom: 1px solid #d9d9d9;
            text-transform: capitalize;
            cursor: pointer;
            &:after{
              content: "";
              background-image: url(../../Assets/Icons/chevron-down.svg);
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-50%);
              width: 22px;
              height: 22px;
            }            
          }
          .valuations{
            ul{
              list-style: none;
              margin: 0;
              padding: 0;
              li{
                span{
                  font-size: 16px;
                  font-weight: normal;
                  margin: 0;
                  width: 50%;
                  &:last-child{
                    float: right;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
        .card-heading {
          position: absolute;
          background-color: $orange;
          border-radius: 6px;
          padding: 10px 15px;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
         
          font-weight: $bold;
          color: $white;
        }
        .form-list {
          li {
            border-bottom: 1px solid #d9d9d9;
            padding: 10px 16px;
            color: $primary-black;
            font-size: 16px;
            display: flex;
            &:last-child {
              border: 0;
            }
            label {
              font-weight: $tiny-bold;
            }
            span {
              font-weight: $boldest;
              margin-left: auto;
              .dim {
                font-weight: $tiny-bold;
                font-size: 14px;
              }
            }
            &.flex-column {
              position: relative;
              span {
                margin-bottom: 5px;
              }
              .icon {
                position: absolute;
                right: 16px;
                top: 20px;
                width: 16px;
                height: 16px;
              }
              .btn {
                margin: 0;
                margin-top: 16px;
                &.orangbtn{
                  background-color: $orange;
                }
              }
            }
          }
        }
      }
    }
  }
  .max-size {
    @extend %container1080;
    margin-bottom: 70px;
    .images-wrapper {
      margin-bottom: 30px;
      .head {
        font-size: 30px;
        color: $primary-black;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .images {
        margin: 0 -16px;
        display: flex;
        flex-wrap: wrap;
        .image-card {
          border-radius: 4px;
          overflow: hidden;
          width: 25%;
          padding: 0px 15px;
          float: left;
          cursor: pointer;
          .avtar {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.specification {
  .head {
    font-size: 30px;
    font-weight: $boldest;
    margin-bottom: 20px;
    color: $primary-black;
    margin-top: 0;
  }
  .table {
    width: 100%;
    border-radius: 6px;
    border: 2px solid #e7e7e7;
    background-color: #f3f3f3;
    border-collapse: collapse;
    margin-bottom: 35px;
    tr {
      th,
      td {
        width: 50%;
        padding: 10px;
        text-align: left;
        color: $primary-black;
        text-transform: capitalize;
        font-size: 14px;
      }
      th {
        position: relative;
        padding-left: 37px;
        font-weight: $boldest;
        .color {
          border: 2px solid #cfcfcf;
          width: 18px;
          height: 18px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
        }
      }
      &:nth-child(even) {
        th,
        td {
          background-color: #e8e8e8;
        }
      }
    }
  }
}
.backbtn {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

// Accordion   

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(startYposition);
  } 
  100% {
      opacity: 1;
      transform: translateY(endYposition);
  }
}

@keyframes fadeOut {
  0% {
      opacity: 1;
      transform: translateY(startYposition);
  } 
  100% {
      opacity: 0;
      transform: translateY(endYposition);
  }
}


.specification{
  .specification-tabs{
    .tabletab{
      display: none;
      animation-name: fadeOut;
      transition: all .5s ease-in-out;
      height: 0;
    }
    &.open{
      .tabletab{
        display: block;
        animation-name: fadeIn;
        height: 100%;
        
      }
      .head{
        background-color: #f39000;
        &:after{
          background-image: url(../../Assets/Icons/minus.svg);
        }
      }
    }
    .head{
      position: relative;
      margin-top: 20px;
      font-size: 20px;
      cursor: pointer;
      border: 1px solid #f1f1f1;
      padding: 10px 15px;
      margin-bottom: 0;
      background: #00933a;
      color: #fff;
      &:after{
        content: "";
        background-image: url(../../Assets/Icons/plus.svg);
        width: 20px;
        height: 20px;
        display: block;
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
        filter: invert(1);
        margin-top: -3px;
      }
    }
  } 
}

// Image Slider

.vehicle-detail-slider {
  position: relative;
  width: 100%;
  float: left;
  .swiper-button-prev{
    width: 80px;
    height: 80px;
    background-color: rgba(29, 29, 27, 0.75);
    border-color: transparent;
    border-radius: 40px;
    transition: background-color 0.2s linear, opacity 0.2s linear;    
    z-index: 1;
    float: right;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
       &:before{
         content: "";
         background-image: url(../../Assets/Icons/chevron-right.svg);
         width: 80px;
         height: 80px;
         background-size: 100% 100%;
         background-position: 0px 1px;
         display: block;
       }
   }
  .next-prev-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1;
    height: 0;
    margin-top: -40px;
    .swiper-button-next{
      width: 80px;
      height: 80px;
      background-color: rgba(29, 29, 27, 0.75);
      border-color: transparent;
      border-radius: 40px;
      transition: background-color 0.2s linear, opacity 0.2s linear;
      z-index: 1;
      float: left;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      &:before{
        content: "";
        background-image: url(../../Assets/Icons/chevron-left.svg);
        width: 80px;
        height: 80px;
        background-size: 100% 100%;
        background-position: -7px 1px;
        display: block;
    }
  }
  
   
  }
  .swiper-wrapper{
    .swiper-slide{
      img{
        width: 100%;
      }
    }
    
  }
}


.gallery-img {
  margin: 20px 0;
  img{
    width: 100%;
  }
}


// @media

@media (max-width: 767px) {

  .vehicle-detail-slider{
    .next-prev-btn{
      .swiper-button-next{
        width: 40px;
        height: 40px;
        &:before{
          width: 40px;
          height: 40px;
          background-position: -3px 1px;
        }
      }
      .swiper-button-prev{
        width: 40px;
        height: 40px;
        &:before{
          width: 40px;
          height: 40px;
        }
      }
    } 
  } 

  .vehicle-detail {
    .vehicle-detail-body {
      .car-detail-column-left,
      .car-detail-column-right {
        width: 100%;
        padding-left: 0;
      }
      .car-detail-column-right {
        margin-bottom: 40px;
        .progress-card {
          margin-top: 50px;
          .card-heading {
            padding: 10px 30px;
          }
        }
      }
    }
    .max-size {
      margin-bottom: 0px;
      .images-wrapper .images .image-card {
        width: calc(50% - 32px);
        margin-bottom: 32px;
      }
    }
  }
}

.list-card{
    @extend %list-card;
    width: 50%;
    .avatar .image-card{
      height: 510px;
    }
    .avatar .carousel-wrapper-class .other-images .other-image{
      width: 105px;
      height: 105px;
      img{
        width: 105px;
        height: 105px;
      }
    }
  }