@import "./../../../Styles/theme";
@import "./../../../Styles/placeholder";
.add-vehicle {
  background-image: url(../../../Assets/Images/1bgimg.jpg);
  min-height: calc(100vh - 153px);
  background-size: cover;
  background-attachment: fixed;
  .inner-container{
    max-width: 1080px;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    display: flex;
  }
  .container-body {
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    position: relative;
    @extend %container500;
    .heading {
      @extend %heading;
      margin-bottom: 1.75rem;
    }
    .form-wrapper {
      display: flex;
      margin: 0 -16px;
      flex-wrap: wrap;
      .form-field {
        width: 100%;
        padding: 0 16px;
        margin-bottom: 15px;
        .label {
          display: flex;
          margin-bottom: 5px;
          label {
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: normal;
          }
          .field-info {
            align-self: flex-end;
            margin-bottom: 3px;
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: normal;
          }
          .field-info-icon {
            margin-left: 8px;
            width: 15px;
            height: 15px;
            align-self: flex-end;
            margin-top: 4px;
          }
        }
        .input-control {
          position: relative;
          .input {
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: normal;            
            &.active {
            }
          }
        }
      }
    }
  }
}

.update-mileage-wrapper {
  height: calc(100vh - 200px);
  max-width: 690px;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 0px;
  padding-bottom: 100px;
  width: 100%;
  .update-mileage {
      max-width: 420px;
      margin: 0 auto;
      margin-bottom: 0px;
      padding-top: 50px;
  }
}
.add-vehicle{
  .container-body{
    .heading{
      span{
        font-size: 1.9rem;
      }
    } 
  } 
} 

@media (max-width: 767px) {
  .update-mileage-wrapper{
    padding: 0px 15px;
    .update-mileage{
      padding-top: 10px;
    }
  }
  .add-vehicle{
    .container-body{
      .heading span{
        font-size: 1.6rem;
      }
    } 
  } 
  .car-informaion {
    .container-body {
      max-width: 700px;
      padding: 10px 16px 50px 16px;
    }
  }
  .car-informaion {
    .container-body {
      .heading {
        margin-bottom: 1.75rem;
      }
    }
  }
  .car-informaion {
    .container-body {
      .form-wrapper {
        .form-field {
          .label {
            label {
              font-size: 16px;
            }
          }
          .input-control {
            .input {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .car-informaion {
    .container-body {
      .form-wrapper {
        margin: 0;
        width: 100%;
        .form-field {
          width: 100% !important;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}
