// -------------------------------------------Primary Colors Starts--------------------------------------------------//
$primary-black: #0b0b0b;
$secondary-black: #484848;
$z-black: #2d2d2d;
$green: #00933a;
$danger: #e40202;
$primary-red: #de2524;
$yellow: #fef200;
$dark-grey: #cccccc;
$white: #ffffff;
$black: #000000;
$input-border: #cfcfcf;
$input-background: $white;
$border-color: #f1f1f1;
$orange: #f39000;
$step-color: #d4d4d4;
// -------------------------------------------Primary Colors End--------------------------------------------------//

$playBtn: #ededed;

// buttons
$in-active: $dark-grey;
$btn-primary-fill-hover: #f2793f;
// ends

// landing page
$orange-with-opacity: rgba(255, 103, 29, 0.2);

// -------------------------------------------Global Sizes--------------------------------------------------//

$largest: 22px;
$larger: 20px;
$large: 18px;
$base: 16px;
$small: 14px;
$smaller: 12px;
$smallest: 10px;
$tiny: 9px;
$tiny-bold: 400;
$bold: 500;
$bolder: 600;
$boldest: 700;
$border-radius: 4px;
$border-width: 2px;
$border-style: solid;
$padding: 15px;
$margin: 10px;

// -------------------------------------------Font Sizes--------------------------------------------------//

$h1: 39px;
$h2: 29px;
$h3: 19px;
$header: $large;
$title: $base;
$description: $small;
$text: $smaller;
$body: 12px;
$caption: 12px;
$small: 8px;

// -------------------------------------------Icon Sizes--------------------------------------------------//

$website: 44px;
$mobile: 24px;
$profile: 34px;
$profile-lg: 50px;
$largecons: $large;
$smallcons: $small;
