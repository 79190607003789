@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.terms-conditions {
  margin-bottom: 39px;
  margin-top: 81px;
  background-image: url(../../Assets/Images/cargo-fixed-design.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 125px);
  background-attachment: fixed;
  .container-terms {
    max-width: 690px;
    margin: 0 auto;
    width: 100%;
    padding: 27px 30px;
    background: #fff;
  //  text-align: center;
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 135px);
    
    .heading{
      @extend %heading;
      margin-bottom: 1.5rem;
      line-height: 1.9rem;
      font-size: 30px;
      color: #2d2d2d;
      font-family: Poppins;       
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 19.95px;
      text-align: center;
      margin-top: 0px;
  }
  }
  .text{
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.terms-conditions{
  .common-banner{
    margin-bottom: 50px;
  }
}

.terms-conditions p {
  margin-bottom: 15px;
}
.terms-conditions h2 {
  margin-bottom: 15px;
}
.terms-conditions .selnumber {
  font-size: 1.5rem;
  font-weight: 700;
  padding-right: 30px;
  display: inline-block;
}
.terms-conditions .color-heading {
  text-transform: uppercase;
  background: #92CDDC;
}
.terms-conditions{
  
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th, td {
    padding: 5px;
    text-align: left;    
  }

}
.terms-conditions th {
  width: 130px;
}
.dsplaycls {
  display: flex;
}
.responsivetable {
  padding-bottom: 20px;
}
.terms-conditions .list1 p span {
  padding-right: 10px;
}
.terms-conditions .list1 p {
  display: flex;
}
.terms-conditions .list1 {
  padding-left: 55px;
}

@media (max-width: 767px) {

  .terms-conditions{
    margin-top: 94px;
    min-height: 100%;
    .container-terms{
      height: auto;
      overflow-y: initial;
      padding: 27px 15px;
    }
  }

}
