@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.selling-process {
   // margin-bottom: 52px;
    margin-top: 81px;
    background-image: url(../../Assets/Images/cargo-fixed-design.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  .container-process {
    // @extend %container1080;
      max-width: 690px;
      margin: 0 auto;
      width: 100%;
      background: #fff;
      padding: 10px 30px;
      max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 134px);
    
    .steps.list-style-none {
        max-width: 390px;
        margin: 0 auto;
    }

    .heading {
      // @extend %heading;
        margin-bottom: 1.5rem;
        color: #000000;
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: 1.44px;
        line-height: normal;
    }
    .text {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}

.process-wrapper{
  .process-main{
    .process-list{
      position: relative;
      text-align: center;
      margin-bottom: 70px;
      .list-number {
          color: #1b893d;
          font-family: Poppins;
          font-size: 46px;
          font-weight: 700;
          text-align: left;
          font-style: normal;
          letter-spacing: 1.84px;
          line-height: normal;
          position: absolute;
          left: 0;
          top: 0;
      }
      .process-box{
        .process-team {
            margin: 0 auto;
            display: inline-block;            
        }
        .process-box-contant {
            text-align: left;
            h4{
              color: #1b893d;
              font-size: 18px;
              font-weight: 600;
              font-style: normal;
              letter-spacing: 0.72px;
              line-height: 31px;
              margin: 10px 0px 0px;
            }
            p{
              font-size: 12px;
              strong{
                letter-spacing: 0.72px;
              }
            }
        }
      }
    }
    .process-lft{
      width: 50%;
      display: inline-block;
      padding-right: 45px;
      vertical-align: top;
    }
    .process-rgt{
      width: 50%;
      display: inline-block;
      padding-left: 45px;
      padding-top: 161px;
      vertical-align: top;
    }
  }
}

.bt-contant {
  text-align: center;
  h3{
    color: #2d2d2d;    
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 20.07px;
    text-align: center;    
    margin-bottom: 15px;
  }
  p{
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: center;  
  }
}

@media (min-width: 768px) {
.selling-process{
  .container-process{
    .process-wrapper{
      .process-main{
        .process-list{
          &.mobileview{
            display: none;
          }
        }
      } 
    } 
  } 
} 

}


@media (max-width: 767px) {

  .selling-process{
    .container-process{
      padding: 30px 15px;
      height: auto;
      overflow-y: initial;
      .heading{
        font-size: 18px;
        font-weight: 600;
      }
      .process-wrapper{
        .bt-contant{
          p{
            font-size: 14px;
            font-weight: 400;
          }
        }
        .process-main{          
          .process-list{
            margin-bottom: 35px;
          }
          .process-lft{
            width: 100%;            
            padding-right: 0;   
            margin-top: 39px;        
          }
          .process-rgt{
            width: 100%;            
            padding-left: 0;
            padding-top: 0;
            .desktopview{
              display: none;
            }            
          }
        } 
      } 
    } 
  } 

}