@import "./../../../Styles/theme";

.radio [type="radio"]:checked,
.radio [type="radio"]:not(:checked),
.radio [type="checkbox"]:checked,
.radio [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio [type="radio"]:checked + label,
.radio [type="radio"]:not(:checked) + label,
.radio [type="checkbox"]:checked + label,
.radio [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.radio [type="radio"]:checked + label:before,
.radio [type="radio"]:not(:checked) + label:before,
.radio [type="checkbox"]:checked + label:before,
.radio [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.radio [type="radio"]:checked + label:after,
.radio [type="radio"]:not(:checked) + label:after,
.radio [type="checkbox"]:checked + label:after,
.radio [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: $green;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio [type="radio"]:not(:checked) + label:after,
.radio [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio [type="radio"]:checked + label:after,
.radio [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.circle-radio {
  font-size: 16px;
  font-weight: $tiny-bold;
  color: $primary-black;
  margin-bottom: 16px;
  span {
    font-size: 16px;
    font-weight: $tiny-bold;
    color: #9d9d9d;
    margin-top: 10px;
  }
}

.radio-button {
  position: relative;
  height: 60px;
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  label {
    background-color: $border-color;
    position: absolute;
    width: calc(100% - 4px);
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0b0b0b;
    font-size: 19px;
    font-weight: 400;
    cursor: pointer;
  }
  [type="radio"]:checked + label {
    font-weight: 700;
    border: 2px solid $green;
  }
}
