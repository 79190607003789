@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.contact-us {
  margin-bottom: 52px;
  margin-top: 81px;
  background-image: url(../../Assets/Images/cargo-fixed-design.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 133px);
  background-attachment: fixed;
  .container-contact {
    // @extend %container1080;
  }
  .common-banner{
    .common-container{
      margin-bottom: 0;
    }
  } 
}

.contactmain{ 
  max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 133px);
    
    .steps.list-style-none {
        max-width: 390px;
        margin: 0 auto;
    }
}

.contact-us-inner {    
    .contactwrapper {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
    }
    .contact-title {
      text-align: center;
      margin-bottom: 30px;
      .heading{
        font-size: 30px;
        color: #2d2d2d;
        font-family: Poppins;
        /* font-size: 32px; */
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 19.95px;
        text-align: center;
        margin-top: 23px;
      }
      p{
        font-size: 16px;
        margin: 10px 0px;
      }
  }
  .contact-lft {      
      .contact-field{
        .form-field{
          width: 100%;
          padding: 0 0px;
          float: left;
          margin-bottom: 10px;
          .label{
            width: 100%;
            float: left;
            label{
              color: #2d2d2d;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 300;
              font-style: normal;
              letter-spacing: normal;
              line-height: 1.5;
              text-align: left;
              float: left;
            }
            .css-bbq5bh{
              float: right;
              width: 15px;
              margin: 0px 0 0px;
              img{
                width: 100%;
              }
            }
          }
          .error-custom {
              font-size: 16px;
              color: #000;
              background: #fff;
              position: absolute;
              width: 120px;
              text-align: center;
              padding: 8px 0;
              border-radius: 6px;
              z-index: 1;
              transition: opacity .6s;
              box-shadow: 0 6px 13px #d2d2d2;
              border: 1px solid #d2d2d2;
              margin-left: 10px;
              &:before{
                content: "x";
                width: 20px;
                height: 20px;
                background: #dc2c2d;
                color: #fff;
                display: inline-block;
                line-height: 20px;
                border-radius: 50%;
                margin-right: 10px;
              }
              &:after{
                content: "";
                position: absolute;
                top: 50%;
                right: 100%;
                margin-top: 0px;
                border-width: 10px;
                border-style: solid;
                border-color: transparent #fff transparent transparent;
                transform: translateY(-50%);
              }
          }
          .logincheck{
            margin: 0px 5px 0px 0px;
            display: inline-block;
            vertical-align: middle;
          }
          .logincheck-label{
            display: inline-block;
            vertical-align: middle;
          }
          &:nth-child(5),&:nth-child(6),&:nth-child(7){
            width: 100%;
          }
          .cg-select{
            padding: 12px 15px;
            border-radius: 4px;
            border: solid 1px #cfcfcf;
            background-color: #ffffff;
            outline: none;
            font-size: 1.375rem;
            width: 100%;
          }
        }
      }
      .submit-mass{
        .btn{
          margin: 0px 0px 0px 0px;
          width: 100%;
        }
      }
      .mass-box{
        textarea{
          width: 100%;
          min-height: 90px;
          border: 1px solid #cfcfcf;
          border-radius: 4px;
          padding: 12px;
          box-shadow: none;
          outline: none;
          margin-bottom: 0;
        }
      }
  }
  .contact-rgt{
    width: 100%;
    padding: 0 10px;
    margin-top: 13px;
    padding-top: 13px;
    .cont-logo{
      display: inline-block;
      vertical-align: top;
      padding-right: 20px;
    }
    .social-media{
      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
          display: inline-block;
          margin-right: 10px;
          &:last-child{
            margin-right: 0;
          }
          a{
            background-color: #00933a;
            width: 45px;
            height: 45px;
            display: block;
            text-align: center;
            line-height: 60px;
            border-radius: 50%;
          }
        }
      }
    }
    .contact-info{
      text-align: left;
      display: inline-block;
      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
          .contact-group{
            margin-bottom: 10px;
            a{
              font-size: 16px;
              color: #000;
              display: inline-block;
              vertical-align: middle;
            }
            img{
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
            }
            p{
              font-size: 16px;
              color: #000;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .contact-us{
    .common-banner{
      margin-bottom: 25px;
    }
    .contactmain{
      padding: 30px 15px 0;
      .contact-us-inner{
        .contact-title{
          margin-bottom: 0px;
          .heading{
            font-size: 20px;
          }
          p{
            font-size: 14px;
          }
        } 
      } 
    }
  }
   
  .contact-us-inner{
    margin-top: 20px;
    .contact-rgt{
      .cont-logo{
        padding-right: 0;
        width: 100%;
      }
    } 
    .contact-lft{
      width: 100%;
      .contact-field{
        .form-field{
          padding: 0 0px;
          .label{
            label{
              line-height: 1.5;
            }
            .css-bbq5bh {
              margin: 6px 0 0px;
            }
          } 
        }
      } 
    }
    .contactwrapper{
      padding: 20px 0px 0;
    }
  }

}