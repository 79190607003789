@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.cookie-policy {
  margin-bottom: 39px;
  margin-top: 81px;
  background-image: url(../../Assets/Images/cargo-fixed-design.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 125px);
  background-attachment: fixed;
  .container-cookie {
    max-width: 690px;
    margin: 0 auto;
    width: 100%;
    padding: 27px 30px;
    background: #fff;
   // text-align: center;
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 135px);
    
    .heading{
        @extend %heading;
        margin-bottom: 1.5rem;
        line-height: 1.9rem;
        font-size: 30px;
        color: #2d2d2d;
        font-family: Poppins;       
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 19.95px;
        text-align: center;
        margin-top: 0px;
    }
    ul {
      li {
          margin-left: 16px;
        }
    }
  }
  .text{
    font-size: 16px;
    margin-bottom: 16px;
  }
}


.cookie-policy{
  .common-banner{
    margin-bottom: 50px;
  }
}


@media (max-width: 767px) {

  .cookie-policy{
    margin-top: 94px;
    min-height: 100%;
    .container-cookie{
      height: auto;
      overflow-y: initial;
      padding: 27px 15px;
    }
  }

}