.table{
    &.table-striped {
        width: 100%;
        border-collapse: collapse;
        tr{
            th{
                border: 1px solid #ddd;
                padding: 10px;
                color: #000;
                font-size: 1.5rem;
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
                background-color: #fff;
            }
            td{
                border: 1px solid #ddd;
                padding: 20px 10px;
                color: #000;
                font-size: 1.5rem;
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
                .delate{
                    background-color: #f39000;
                }
                .primary{
                    padding: 5px;
                }
                
                
            }
            &:nth-child(odd){
                background-color: #e6e0e0;
            }
           
        } 
    }
}

.addrolebtn {
    width: 100%;
    text-align: right;
    .addrole{
        float: right;
    }
}


form.form-horizontal.form-label-left{
    .form-group {
        width: 100%;
        float: left;
        .control-label{
            display: inline-block;
            vertical-align: middle;
            font-size: 1.5rem;
            float: left;
            padding: 4px 19px;
            margin-bottom: 27px;         
        }
        .control{
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
            width: calc(100% - 152px);
            float: right;
            /* margin-left: 12px; */
            padding: 11px;
            .form-control{
                padding: 0px 15px;
                border-radius: 4px;
                background-color: #ffffff;
                outline: none;
                font-size: 16px;
                height: 40px;
                border: 2px solid #d2d2d2;
                color: #2d2d2d;
                width: 100%;
            }
        }
        .addrole{
            display: inline-block;
        }
    }
}