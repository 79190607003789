@import "./../../../Styles/theme";
@import "./../../../Styles/placeholder";


.car-informaion {
  margin-top: 81px;
  margin-bottom: 52px;
  width: 100%;
  float: left;
  overflow: hidden;
  background-image: url(../../../Assets/Images/cargo-fixed-design.png);
  background-repeat: no-repeat;  
  position: relative;  
  background-size: cover;  
  background-attachment: fixed;
  min-height: calc(100vh - 132.6px);
  .container-wrapper-main{
    overflow: hidden;
  }
  .container-body {
    padding: 10px 0 50px 0;
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    padding-bottom: 30px;
    height: calc(100vh - 248px);
    
    .heading {
      @extend %heading;
      margin-bottom: 1.75rem;
      font-size: 2rem;
      color: #2d2d2d;
      
    }
    .form-wrapper {
      display: flex;
      margin: 0 0px;
      flex-wrap: wrap;
      .bottom-contant-setep{
        .bottom-contant{
          .offer-cargo{
            ul{
              li{
                font-size: 1.4rem;
              }
            }
          }
        }
      }
      button.btn.primary{
        // background: transparent;
        // color: #00933a;
        // font-size: 70px;
        // font-weight: 700;
        // font-family: 'Poppins', sans-serif;
        // letter-spacing: -10px;
        font-weight: bold;
          width: 280px;
          padding: 4px;
          color: black;
          border: 5px solid black;
          border-radius: 10px;
          font-family: 'Poppins';
          padding-right: 30px;
        margin: 0 auto;
        &:hover{
          box-shadow: none;
        }
      }
      .form-field {
        width: 100%;
        padding: 0 0px;
        margin-bottom: 15px;
        .circle-radio{
          span{
            font-size: 1.2rem;
            color: #2d2d2d;
            font-weight: 300;
          }
          label{
            font-size: 1.2rem;
            color: #2d2d2d;
            font-weight: 300;
          }
        }
        .label {
          display: flex;
          margin-bottom: 0px;
          label {
            font-size: 1rem;
            font-weight: 300;
            color: #2d2d2d;
            font-family: Poppins;
          }
          .field-info {
            font-size: 1rem;
            font-weight: 300;
            align-self: flex-end;
            margin-bottom: 3px;
            color: #2d2d2d;
            font-family: Poppins;
          }
          .field-info-icon {
            margin-left: 8px;
            width: 15px;
            height: 15px;
            align-self: flex-end;
            margin-top: 0px;
          }
        }
        .input-control {
          position: relative;
          .error-custom {
            font-size: 16px;
            color: #000;
            background: #fff;
            position: absolute;
            width: 120px;
            text-align: center;
            padding: 8px 0;
            border-radius: 6px;
            z-index: 1;
            transition: opacity .6s;
            box-shadow: 0 6px 13px #d2d2d2;
            border: 1px solid #d2d2d2;
            margin-left: 10px;
            &:after{
              content: "";
              position: absolute;
              top: 50%;
              right: 100%;
              margin-top: 0px;
              border-width: 10px;
              border-style: solid;
              border-color: transparent #fff transparent transparent;
              transform: translateY(-50%);
            }
            &:before{
              content: "x";
              width: 20px;
              height: 20px;
              background: #dc2c2d;
              color: #fff;
              display: inline-block;
              line-height: 20px;
              border-radius: 50%;
              margin-right: 10px;
            }
        }
          .input {
            color: $primary-black;           
            font-size: 16px;
            font-weight: $tiny-bold;
            border: 1px solid #cbcbcb;
            &.active {
            }
          }
        }
      }
    }
  }
}

//Valuation Price

.valuation-price{
  width: 100%;
  float: left;
  margin-bottom: 20px;
  .valuation-price-table{
    margin: 0px -10px;
    .columns {
      float: left;
      width: 20%;
      padding: 0px 10px;
      .price {
        list-style-type: none;
        border: 1px solid #eee;
        margin: 0;
        padding: 0;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        &:hover {
          box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
        }
        .price-title {
          background-color: #f39000;
          color: white;
          font-size: 18px;
          font-weight: 700;
        }
        li {
          border-bottom: 1px solid #eee;
          padding: 10px;
          text-align: center;
        }
        .grey {
          background-color: #eee;
          font-size: 16px;
        }
      }
    }
  } 
  

}


@media (max-width: 767px) {

  .car-informaion{
    .container-body{
      .heading{
        font-size: 1.5rem;
      }
    } 
  } 

  .valuation-price{
    .valuation-price-table{
      margin: 0px -5px;
      .columns{
        width: 50%;
        padding: 0px 5px;
        margin-bottom: 15px;
      }
    } 
  } 
  
  .car-informaion {
    .container-body {
      max-width: 700px;
      padding: 10px 16px 50px 16px;
    }
  }
  .car-informaion{
    .container-body{
      .heading{
        margin-bottom: 1.75rem;
      }
    }
  }
  .car-informaion{
    .container-body{
      .form-wrapper{
        .form-field{
          .label{
            label{
              font-size: 16px;
            }
          } 
          .input-control{
            .input{
              font-size: 16px;
            }
          } 
        } 
      } 
    } 
  } 
}



@media (max-width: 580px) {
  .car-informaion {
    .container-body {
      .form-wrapper {
        margin: 0;
        width: 100%;
        .form-field {
          width: 100% !important;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
