@import "./../../Styles/theme";
@import "./../../Styles/placeholder";
.vehicle-detail {
  .vehicle-detail-body {
    @extend %container1080;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding-top: 72px;
    .car-detail-column-left {
      width: 50%;
      .title {
        font-size: 20px;
        text-transform: uppercase;
        color: $primary-black;
        border: 4px solid #000000;
        background-color: #edb92d;
        border-radius: 6px;
        line-height: 100%;
        letter-spacing: 1.42px;
        width: 200px;
        display: flex;
        justify-content: center;
        // color: $primary-black;
        // font-size: 14px;
        // font-weight: $bold;
        // border-radius: 4px;
        // background-color: $yellow;
        // line-height: 16px;
        // display: inline-block;
        // padding: 7px 25px;
        // margin-bottom: 16px;
        // text-transform: uppercase;
      }
      .heading {
        @extend %heading;
        margin-bottom: 16px;
        line-height: normal;
      }
      .car-info {
        margin-bottom: 16px;
        .info {
          font-size: 14px;
          font-weight: $tiny-bold;
          color: #2d2d2d;
          margin-right: 25px;
          position: relative;
          font-weight: $tiny-bold;
          &::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            right: -15px;
            top: 10px;
            background-color: #2d2d2d;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
      .car-feature {
        display: flex;
        flex-wrap: wrap;
        li {
          border-radius: 4px;
          border: 1px solid #d8d8d8;
          background-color: #e4e4e4;
          font-size: 12px;
          font-weight: $bold;
          padding: 10px 12px;
          margin-right: 5px;
          margin-bottom: 5px;
          color: #696969;
          display: inline-block;
          line-height: 13px;
        }
      }
      .additional-spec {
        margin-bottom: 25px;
        .common-heading {
          color: $primary-black;
          font-size: 18px;
          font-weight: $bold;
          .spec-text {
            font-size: 14px;
            color: $secondary-black;
            line-height: 25px;
            display: inline-block;
            font-weight: $tiny-bold;
          }
        }
      }
    }
    .car-detail-column-right {
      width: 50%;
      padding-left: 10%;
      .progress-card {
        position: relative;
        background-color: #d2d2d2;
        padding: 50px 30px 30px 30px;
        .card-heading {
          position: absolute;
          background-color: $danger;
          border-radius: 6px;
          padding: 10px 40px;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 16px;
          font-weight: $bold;
          color: $white;
        }
        .form-list {
          li {
            border-bottom: 1px solid #d9d9d9;
            padding: 10px 16px;
            color: $primary-black;
            font-size: 16px;
            display: flex;
            &:last-child {
              border: 0;
            }
            label {
              font-weight: $tiny-bold;
            }
            span {
              font-weight: $boldest;
              margin-left: auto;
              .dim {
                font-weight: $tiny-bold;
                font-size: 14px;
              }
            }
            &.flex-column {
              position: relative;
              span {
                margin-bottom: 5px;
              }
              .icon {
                position: absolute;
                right: 16px;
                top: 20px;
                width: 16px;
                height: 16px;
              }
              .btn {
                margin: 0;
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
  .max-size {
    @extend %container1080;
    margin-bottom: 70px;
    .images-wrapper {
      margin-bottom: 30px;
      .head {
        font-size: 20px;
        color: $primary-black;
        margin-bottom: 20px;
      }
      .images {
        margin: 0 -16px;
        display: flex;
        flex-wrap: wrap;
        .image-card {
          border-radius: 4px;
          overflow: hidden;
          width: 25%;
          padding: 0px 15px;
          float: left;
          cursor: pointer;
          .avtar {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

// @media

@media (max-width: 767px) {
  .vehicle-detail {
    .vehicle-detail-body {
      .car-detail-column-left,
      .car-detail-column-right {
        width: 100%;
        padding-left: 0;
      }
      .car-detail-column-right {
        margin-bottom: 40px;
        .progress-card {
          margin-top: 50px;
          .card-heading {
            padding: 10px 30px;
          }
        }
      }
    }
    .max-size {
      margin-bottom: 0px;
      .images-wrapper .images .image-card {
        width: calc(50% - 32px);
        margin-bottom: 32px;
      }
    }
  }
}
.map_CarGrid{
    width:100% !important;
}