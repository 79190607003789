@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.cargo-home {
  overflow: hidden;
  background-image: url(../../Assets/Images/cargo-fixed-design.png);
  position: relative;
  margin-top: 81px;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 132.6px);
  background-attachment: fixed;
  margin-bottom: 52px;
  .homebg{
    width: 100%;
    height: auto;
    display: block;
  }
  .banner-fold {
   
    .banner-content-wrapper {
      margin-top: 0px;
      .banner-heading {
        font-size: 3.125rem;
        font-weight: $boldest;
        line-height: 4.0625rem;
        margin-bottom: 1rem;
        letter-spacing: 0.103125rem;
      }
      .tagline {
        font-size: 1.5rem;
        font-weight: $tiny-bold;
        margin-bottom: 1rem;
      }
      .banner-form-group {
        margin-bottom: 1.5rem;
        .input {
          min-width: 25.125rem;
          height: 44px;
          text-transform: uppercase;
          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            text-transform: capitalize;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            text-transform: capitalize;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            text-transform: capitalize;
          }
          &:-moz-placeholder { /* Firefox 18- */
            text-transform: capitalize;
          }
        }
      }
      .banner-rating {
        margin-bottom: 6rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .reviews {
          font-size: 1.125rem;
          font-weight: $tiny-bold;
          margin-left: 15px;
          margin-top: 13px;
        }
        .icon-star {
          margin-right: 1rem;
          display: block;
        }
        .icon-stars {
          margin-top: 13px;
        }
      }
    }
  }

  // campare companies and car
  .compare-compnies {
    padding-top: 2.5rem;
    max-width: 77.5rem;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    .head {
      border-bottom: 2px solid $border-color;
      .heading {
        text-align: center;
        font-size: 1.5rem;
        font-weight: $tiny-bold;
        margin-bottom: 1.375rem;
        padding-bottom: 1.375rem;
        span {
          font-weight: $boldest;
        }
      }
      .company-logos {
        padding: 0 37px;
        display: flex;
        flex-wrap: nowrap;
        .company-logo {
          padding: 10px;
          width: calc(100% / 6);
        }
      }
    }
    .company-features {
        margin: 0 -2.6875rem;
        padding-top: 3.5rem;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 3.5rem;
      .feature {
        width: 25%;
        padding: 0 2.6875rem;
        text-align: center;
        &.caricon{
          svg.icon{
            background: #00933a;
            padding: 18px;
          }
        }
        .icon {
          width: 6.25rem;
          height: 6.25rem;
          margin-bottom: 3rem;
          border-radius: 8px;
          font-size: 40px;
          margin: 0px 10px;
          line-height: 100px;
          display: inline-block;
          -webkit-transition: all 0.6s ease;
          -moz-transition: all 0.6s ease;
          -ms-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          transition: all 0.6s ease;
          &:hover {
            border-radius: 50%;
            background: #b71c1c;
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        .heading {
          font-size: 18px;
          font-weight: $tiny-bold;
          letter-spacing: -0.040625rem;
          margin-bottom: 2rem;
        }
        .text-description {
          font-size: 16px;
          line-height: 1.8125rem;
          font-weight: $tiny-bold;
          letter-spacing: -0.025rem;
          color: $secondary-black;
        }
      }
    }
  }
  // why sell
  .why-sell {
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.11);
    background-color: #f7f7f7;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .featured-image {
      width: 50%;
      margin-bottom: 50px;
      padding-right: 50px;
      .featured-icon {
        width: 100%;
      }
    }
    .content {
      width: 50%;
      .heading {
        @extend %heading;
      }
      .featured-point {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 50%;
          padding: 0 3.75rem;
          margin-bottom: 3.5rem;
          .point {
            // padding-left: calc(1.75rem + 30px);
            position: relative;
            &::before {
              top: 5px;
              left: -50px;
              position: absolute;
              content: "";
              background: url("./../../Assets/Icons/arrow-right.png");
              width: 24px;
              height: 24px;
              background-size: contain;
            }
            .point-heading {
              font-size: 1.5rem;
              font-weight: $tiny-bold;
              letter-spacing: -0.040625rem;
              margin-bottom: 1.125rem;
              color: $primary-black;
            }
            .point-text {
              font-size: 1rem;
              font-weight: $tiny-bold;
              line-height: 1.8125rem;
              letter-spacing: -0.025rem;
              color: $secondary-black;
            }
          }
        }
      }
    }
  }

  // sell any car
  .sell-any-car {
    background-color: $primary-red;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .heading {
      font-size: 2.875rem;
      color: $white;
      margin-bottom: 3.5rem;
      font-weight: $boldest;
    }
    .car {
      margin: 0 -10px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin: 0 10px;
        width: calc(16.6666% - 20px);
        background-color: $white;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // sell you car
  .sell-your-car {
    margin: 0 -2rem;
    padding: 2.5rem 0;
    .inner-container {
      max-width: 1080px;
      width: 100%;
      padding: 0 16px;
      margin: 0 auto;
      display: flex;
      .content {
        width: 50%;
        padding: 0 2rem;
        .text-description {
          @extend %heading;
        }
        .text {
          font-size: 1rem;
          font-weight: $tiny-bold;
          line-height: 1.865rem;
          margin-bottom: 1.5rem;
          color: $secondary-black;
        }
        .btn {
          margin-top: 1rem;
          margin-left: 0;
        }
      }
      .featured-image {
        padding: 0 2rem;
        width: 50%;
      }
    }
  }

  // Join Thousands of Happy Customers
  .customer-review {
    padding: 2.5rem 0;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.11);
    background-color: #f7f7f7;
    .head {
      margin-bottom: 5rem;
      .heading {
        @extend %heading;
        text-align: center;
      }
    }
    .customers {
      display: flex;
      margin: 0 -22px;
      flex-wrap: wrap;
      .customer-card {
        margin: 0 1.375rem;
        width: calc(33.3333% - 44px);
        background-color: $white;
        padding: 3.25rem 3.125rem 5rem 3.125rem;
        border-radius: 6px;
        border: 2px solid #ebeaea;
        .profile {
          width: 6.25rem;
          height: 6.25rem;
          border-radius: 50%;
          margin-bottom: 2.5rem;
          img {
            width: 100%;
          }
        }
        .review-start {
          margin-bottom: 1.865rem;
        }
        .title {
          font-size: 1.5rem;
          font-weight: $tiny-bold;
          color: $primary-black;
          margin-bottom: 1.125rem;
        }
        .text {
          font-size: 1rem;
          font-weight: $tiny-bold;
          line-height: 1.8125rem;
          color: $secondary-black;
          margin-bottom: 1.5rem;
        }
        .name {
          font-size: 1.125rem;
          font-weight: $boldest;
          color: $primary-black;
          margin-bottom: 10px;
        }
        .car-sold {
          font-size: 1.125rem;
          font-weight: $tiny-bold;
          color: #848484;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .cargo-home {
    .sell-any-car {
      padding-top: 1.625rem;
      padding-bottom: 2.625rem;
    }
    .sell-your-car {
      margin: 0 0rem;
      padding: 2.75rem 0;
    }
    .customer-review {
      padding: 20px 0;
      margin-bottom: 0;
      .head {
        margin-bottom: 30px;
        .heading{
          margin-bottom: 0;
        }
      }
    }
    .banner-fold {
      background: none;
      background-color: #f1f1f1;
      height: auto;
      .banner-content-wrapper {
        text-align: center;
        margin: 0 auto;
        .banner-heading {
          font-size: 32px;
          margin-bottom: 20px;
        }
        .tagline {
          font-size: 16px;
          margin-bottom: 15px;
        }
        .banner-form-group {
          text-align: center;
          margin-bottom: 0;
          .input {
            margin-bottom: 20px;
          }
        }
        .banner-rating {
          justify-content: center;
          margin-bottom: 30px;
          .icon-star {
            width: 150px;
          }
          .icon-stars {
            width: 105px;
          }
          .reviews {
            display: block;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .compare-compnies {
      padding-top: 30px;
      .head {
        .heading {
          margin-bottom: 30px;
          padding-bottom: 0;
        }
        .company-logos {
          padding: 0px;
          margin: 0 -15px;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          overflow: auto;
          .company-logo {
            margin: 0 5px;
            width: 101px;
          }
        }
      }
      .company-features {
        padding-top: 30px;
        padding-bottom: 30px;
        .feature {
          width: 100%;
          margin-bottom: 30px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -15px;
            height: 2px;
            width: 50%;
            left: 50%;
            transform: translateX(-50%);
            background: #f1f1f1;
          }
          .icon {
            margin-bottom: 20px;
          }
        }
      }
    }
    .why-sell {
      padding-bottom: 20px;
      padding-top: 30px;
      .container {
        flex-wrap: wrap;
      }
      .featured-image,
      .content {
        width: 100%;
      }
      .featured-image {
        margin-bottom: 30px;
      }
      .content {
        .heading {
          margin-bottom: 30px;
        }
        .featured-point {
          .item {
            width: 100%;
            padding-right: 0;
            padding-left: 50px;
            margin-bottom: 15px;
            .point {
              position: relative;
              &::after {
                position: absolute;
                content: "";
                width: 50px;
                height: 2px;
                left: 0px;
                bottom: -10px;
                background-color: #f1f1f1;
              }
              .point-heading {
                font-size: 20px;
              }
              .point-text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .sell-any-car {
      .car {
        flex-wrap: nowrap;
        overflow-y: auto;
        .item {
          width: 150px;
          height: auto;
          padding: 10px;
          img {
            max-width: 80px;
          }
        }
      }
    }
    .sell-your-car {
      .inner-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .content {
          width: 100%;
          text-align: center;
          .text-description {
            text-align: center;
            margin-bottom: 20px;
          }
          .text {
            font-size: 14px;
          }
        }
        .featured-image {
          width: 80%;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
    .customer-review {
      .customers {
        .customer-card {
          width: 100%;
          margin-bottom: 15px;
          padding: 2.25rem 3.125rem 2.25rem 3.125rem;
          .title {
            font-size: 20px;
          }
          .text,
          .name,
          .car-sold {
            font-size: 14px;
          }
        }
      }
    }
  }
}


//  New Home

.registered-fold {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  width: 100%;
  height: 100%;
  .registered-fold-inner {
      position: relative;
      width: 100%;
      float: left;
      height: 100%;
  }
}

.value-content-wrapper{
  text-align: center;
  padding: 20px 0px 0px;
  .value-heading{
    font-size: 2.625rem;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 33.02px;
  }
  .ideal-button{
    width: 280px;
    padding: 4px;
    color: black;
    border: 5px solid black;
    border-radius: 10px;
    font-family: 'Poppins';
    padding-right: 30px;
    font-weight: bold;
    & > span {
      font-weight: 900;    
    font-size: 22px;    
    position: absolute;
    }    
  }
}
.bottom-contant{
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
  h2{
    font-size: 2.25rem;
    font-weight: 500;
    letter-spacing: 1.44px;
  }
  .offer-cargo{
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 700;
        padding: 0px 2px;
        letter-spacing: 1.62px;
        font-family: 'Poppins';
        span{
          color: #1b893d;
          font-size: 2.5rem;
          line-height: normal;
          vertical-align: middle;
        }
      }
    }
  }
}

.input-checkbox {
  color: #fff;
  margin-top: 15px;
}

.input-reg{
  .input-inner{
    position:relative;
    display:inline-block;
    
    .input{
      border: 4px solid #000;
      background: #edb92d;
      font-size: 1.3rem;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      height: 40px;
      border-radius: 8px;
      width: 280px;
      &::-webkit-input-placeholder{
        color: #000;
      }
      &:-moz-placeholder{
        color: #000;
      }
      &::-moz-placeholder{
        color: #000;
      }
      &:-ms-input-placeholder{
        color: #000;
      }
      &::placeholder{
        color: #000;
      }
    }
    .clear-n-restart{
      position: absolute;
      top: 8px;
      font-size: 250%;
      color: red;
      right: 9px;
      border: 1px solid red;
      border-radius: 5px;
      cursor: pointer;
      line-height: 19px;
      padding-top: 1px;

      &:hover{
          background-color: #fdcece;
      }
    }
  }
}
.input-normal{
  .input-normal-inner{
    border: 4px solid #000;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 8px;
    width: 280px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #000;
    font-family: Poppins;
    input {
      width: 100% !important;
      height: 32px;
      background: #2d2d2d;
      background: -moz-linear-gradient(top,  #2d2d2d 0%, #434243 51%, #272627 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  #2d2d2d 0%,#434243 51%,#272627 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  #2d2d2d 0%,#434243 51%,#272627 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d2d2d', endColorstr='#272627',GradientType=0 ); /* IE6-9 */
      border: 1px solid #000;
      outline: none;
      text-align: center;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 500;
      font-family: Poppins;
      padding: 0;
      -moz-appearance: textfield;
      &::-webkit-input-placeholder{
        color: #fff;
      }
      &:-moz-placeholder{
        color: #fff;
      }
      &::-moz-placeholder{
        color: #fff;
      }
      &:-ms-input-placeholder{
        color: #fff;
      }
      &::placeholder{
        color: #fff;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
 
}

.nextarrow{
  span{
    color: #00933a;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
}
.pricedetail-new{
  margin-top: 10px;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      display: inline-block;
      color: #fff;
      font-size: 16px;
      padding: 0px 8px;
      vertical-align: middle;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }
  .priceshow{
    span{
      font-size: 30px;
      color: #fff;
      font-weight: 700;
      letter-spacing: 1px;
    }
    p {
        color: #fff;
        font-size: 14px;
        margin-bottom: 10px;
        &.title{
          font-size: 16px;
          letter-spacing: 1px;
          margin-bottom: 0;
        }
    }
  }
}

.side-image {
  width: 450px;
  margin-bottom: -150px;
}

.bottom-image {
  width: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

@media only screen and (max-width: 1379px) {
  .bottom-image {
    width: 350px;
    margin-top: 25px;
  }
  .side-image {
    width: 0px;
    height: 0px;
  }
}

@media (max-width: 1920px) and (min-width:1685px){

  .value-content-wrapper{
    .value-heading{
      margin-top: 0px;
    }
  } 
  .pricedetail-new{
    ul{
      li{
        font-size: 20px;
      }
    } 
  } 

  .pricedetail-new {
      margin-top: 60px;
      .priceshow{
        p.title{
          font-size: 18px;
        }
      } 
  }

}

@media (max-width: 1024px) and (min-width:768px){
  .add-vehicle, .form-steps{
    margin-top: 96px;
  }
.cargo-home{    
  background-image: url(../../Assets/Images/Gradients_from_mobile.png);   
  margin-top: 97px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  margin-bottom: 52px;
  &:after{
    content: "";
    position: fixed;
    right: 0;
    background-image: url(../../Assets/Images/VW_Golf.png);
    display: block;
    width: 300px;
    height: 290px;
    background-size: 100% 100%;
    top: 42%;
   
  //  margin-top: 50px;
  }
  &:before{
    content: "";
    position: fixed;
    left: 0;
    background-image: url(../../Assets/Images/Range_Rover.png); 
    display: block;
    width: 300px;
    height: 290px;
    background-size: 100% 100%;
    top: 42%;
   // margin-top: -46px;
  }  
}

}

@media (max-width: 767px) {

  .pricedetail-new{    
    .priceshow{
      span{
        font-size: 20px;
      }
      p{
        margin-bottom: 5px;
      }
    } 
  } 

  .value-content-wrapper{
    .value-heading{
      font-size: 2.225rem;
    }
    .pricedetail-new {
        margin-top: 10px;
    }
  } 
  .nextarrow{
    span{
      z-index: 100;
      position: relative;
    }
  }
  .input-number{
    .input-number-inner{
      input{
        height: 33PX;
        font-size: 1.525rem;
      }
    } 
  } 
  .pricedetail-new{
    ul{
      li{
        font-size: 13px;
        padding: 0px 6px;   
      }
    }
    .priceshow{
      p.title{
        font-size: 14px;
      }
    }  
  } 

  .cargo-home{    
    background-image: url(../../Assets/Images/Gradients_from_mobile.png);   
    margin-top: 52px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-attachment: fixed;
    margin-bottom: 0;        
    &:before{
      content: "";
      position: fixed;
      left: 0;
      background-image: url(../../Assets/Images/Range_Rover.png); 
      display: block;
      width: 200px;
      height: 190px;
      background-size: 100% 100%;
      top: 50%;
      margin-top: 0px;
    }
    .registered-fold:before{
      content: "";
      position: fixed;
      right: 0;
      background-image: url(../../Assets/Images/VW_Golf.png);
      display: block;
      width: 200px;
      height: 190px;
      background-size: 100% 100%;
      top: 50%;
      top: 50%;
      margin-top: 0px;
    }
    .bottom-contant{
      margin-bottom: 50px;
      bottom: 0;
      padding: 0 12px;
      .offer-cargo{
        max-width: 200px;
        margin: 0 auto;
        text-align: left;
        ul{
          li{
            span{
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .cargo-home{
    &.cargo-next{      
      .bottom-contant{
        margin-top: 300px;
      }
    }
  } 
}


@media (max-width: 360px) and (min-width:320px){
 
  .value-content-wrapper{
    .value-heading{
      font-size: 1.825rem;
      line-height: 20.52px;
    }
  } 
  .registered-form-group{
    .input{
      font-size: 1.575rem;
      height: 33px;
    }
  } 
  .input-number{
    .input-number-inner{
      input{
        height: 25PX;
        font-size: 1.125rem;
      }
    } 
  } 
  .pricedetail-new{
    .priceshow{
      p{
        margin-bottom: 2px;
      }
    } 
    ul{
      li{
        font-size: 11px;
      }
    } 
  } 

}

// @media screen and (orientation: portrait) {
//   .cargo-home{
    
//   }
// }

@media screen and (orientation: landscape) {

  .bottom-contant{
    position: relative;
    margin-top: 300px;
  }

  .cargo-home{
    .bottom-contant{
      .offer-cargo{
        max-width: 100%;
        ul{
          li{
            font-size: 1.5rem;
          }
        }
      }
    } 
  } 

}