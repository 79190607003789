@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.customer-review {
  margin-bottom: 0px;
  .container-customer-review {
    @extend %container1080;
    .heading {
      @extend %heading;
    }
    .text {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}
